import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../contexts/AppContext';
import DifensoTeamsApi from '../../api/DifensoTeamsApi';
import { Dialog, DialogFooter, DialogType, Icon, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";

// render
const Authenticate: React.FC = () => {
  const { t } = useTranslation();
  const { currentUserDwsPassword, setCurrentUserDwsPassword, teamsContext } = useContext(AppContext);
  const [dwsPassword, setDwsPassword] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [checkingPassword, setCheckingPassword] = useState<boolean>();

  const checkPassword = () => {
    if (teamsContext && teamsContext.userPrincipalName && dwsPassword && !checkingPassword) {
      const tApi = new DifensoTeamsApi('api');
      setCheckingPassword(true);
      setErrorMessage(undefined);
      tApi.validateDwsPassword(teamsContext.userPrincipalName, dwsPassword).then((result) => {
        if (result.data) {
          localStorage.setItem('dwspwd', dwsPassword);
          setCurrentUserDwsPassword(dwsPassword);
        } else {
          localStorage.removeItem('dwspwd');
          setErrorMessage(t('Error.DwsAuthenticationFailed'));
        }
        setCheckingPassword(false);
      }).catch((error) => {
        console.log(error);
        localStorage.removeItem('dwspwd');
        setErrorMessage(t('Error.DwsAuthenticationFailed'));
        setCheckingPassword(false);
      });
    }
  }

  return (<>
    <Dialog
      hidden={(!!currentUserDwsPassword)}
      minWidth={400}
      dialogContentProps={{ title: t('DwsAuthentication.Title'), type: DialogType.normal }}>
      {
        (teamsContext) && (
          <>
            <div className="dwsPasswordDialogText">{t("DwsAuthentication.Login")}<br /><b>{teamsContext.userPrincipalName ?? "-"}</b></div>
            <div className="dwsPasswordDialogText">{t("DwsAuthentication.EnterPassword")}</div>
            <div>
              <TextField
                type="password"
                defaultValue=""
                value={dwsPassword}
                onChange={(ev, newValue) => setDwsPassword(newValue)}
                onKeyPress={(keyev) => { if (keyev.key === "Enter") checkPassword(); }} />
            </div>
            {errorMessage && (
              <div className="error"><Icon iconName="error"></Icon> {errorMessage}</div>
            )}
            <DialogFooter>
              {!checkingPassword && (
                <PrimaryButton text={t("DwsAuthentication.Validate")} disabled={checkingPassword} onClick={checkPassword} className="primaryButton" />
              )}
              {checkingPassword && (
                <Spinner size={SpinnerSize.small} styles={{ root: [{ display: 'inline-flex' }] }} />
              )}
            </DialogFooter>
          </>)
      }
      {!teamsContext && (
        <Spinner size={SpinnerSize.small} styles={{ root: [{ display: 'inline-flex' }] }} />
      )}
    </Dialog>
  </>
  );
};

export default Authenticate;