import BaseApi from "./BaseApi";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";

/**
 * The SharePoint API class.
 *
 * @class
 * @param {string} _bearer The connected user bearer.
 */
class SharePointApi extends BaseApi {
  /**
   * Get the drive informations.
   *
   * @return {Promise} The request promise.
   */
  public searchSite = async (term: string): Promise<microsoftGraph.Site[]> => {
    const res = await this._get(`?search=${term}`, {});
    return res.data.value;
  };
}

export default SharePointApi;
