import React, { useContext, useState, useMemo, useEffect, useCallback } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";
import {
  IColumn,
  ShimmeredDetailsList,
  Stack,
  registerIcons,
  SelectionMode,
  Selection,
  Link,
  Image,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import { getFileTypeIconProps, IFileTypeIconOptions, FileIconType } from "@uifabric/file-type-icons";
import * as mime from "mime-types";
import filesize from "filesize";
import Utils from '../../common/Utils';
import { DriveType } from "../../common/DriveType";
import AppContext from "../../contexts/AppContext";
import DifensoTeamsApi from '../../api/DifensoTeamsApi';
import ApprovalRequestDialog from '../ApprovalRequestDialog/ApprovalRequestDialog';
import encryptIcon from "../../images/encrypt.svg";
import { DialogType } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

registerIcons({
  icons: {
    "encrypt-svg": <Image src={encryptIcon} />,
  },
});

export interface DriveItemsListProps {
  driveType: DriveType;
  callbackItems: () => microsoftGraph.DriveItem[] | undefined;
  setSelectedItem: (item: microsoftGraph.DriveItem | undefined) => void;
  setErrorMessage: (message: string | undefined) => void;
  onDownloadedFile: (filename: string) => void;
}

const DriveItemsList: React.FC<DriveItemsListProps> = ({
  driveType,
  callbackItems,
  setSelectedItem,
  setErrorMessage,
  onDownloadedFile,
}: DriveItemsListProps) => {
  const [items, setItems] = useState<microsoftGraph.DriveItem[] | undefined>(undefined);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [hideDecryptDialog, setHideDecryptDialog] = useState<boolean>(true);
  const [fileIdToDecrypt, setFileIdToDecrypt] = useState<string | undefined>(undefined);
  const [fileIdToApprove, setFileIdToApprove] = useState<string | undefined>(undefined);
  const [gettingFile, setGettingFile] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = appContext.teamsContext?.hostClientType === "ios" || appContext.teamsContext?.hostClientType === "android";

  useEffect(() => {
    if (history.location.pathname) {
      setTimeout(() => window.scrollTo(0, 0), 300);
    }
  }, [history.location]);

  useEffect(() => {
    setColumns(isMobile ? [
      {
        key: "colIcon",
        name: t("List.Columns.FileType"),
        className: "fileIconCell",
        iconClassName: "fileIconHeaderIcon",
        iconName: "Page",
        isIconOnly: true,
        fieldName: "name",
        minWidth: 20,
        maxWidth: 20,
      },
      {
        key: "colName",
        name: t("List.Columns.Name"),
        fieldName: "name",
        minWidth: 80,
        isRowHeader: true,
      },
      {
        key: "colSize",
        name: t("List.Columns.Size"),
        fieldName: "size",
        minWidth: 50,
      },
    ] : [
      {
        key: "colIcon",
        name: t("List.Columns.FileType"),
        className: "fileIconCell",
        iconClassName: "fileIconHeaderIcon",
        iconName: "Page",
        isIconOnly: true,
        fieldName: "name",
        minWidth: 20,
        maxWidth: 20,
      },
      {
        key: "colName",
        name: t("List.Columns.Name"),
        fieldName: "name",
        minWidth: 200,
        maxWidth: 350,
        isPadded: true,
        isRowHeader: true,
      },
      {
        key: "colModified",
        name: t("List.Columns.Modified"),
        fieldName: "lastModifiedDateTime",
        minWidth: 200,
        maxWidth: 350,
        isPadded: true,
      },
      {
        key: "colModifiedBy",
        name: t("List.Columns.ModifiedBy"),
        fieldName: "lastModifiedBy",
        minWidth: 200,
        maxWidth: 350,
        isPadded: true,
      },
      {
        key: "colSize",
        name: t("List.Columns.Size"),
        fieldName: "size",
        minWidth: 100,
        maxWidth: 350,
        isPadded: true,
      },
      {
        key: "colShared",
        name: t("List.Columns.Shared"),
        fieldName: "shared",
        minWidth: 125,
        maxWidth: 350,
        isPadded: true,
      },
    ]);
    setItems(callbackItems());
  }, [callbackItems, t, isMobile]);

  const _selection = useMemo(() => {
    return new Selection({
      onSelectionChanged: () => {
        const currentSelectedItem =
          _selection && _selection.getSelectedCount() > 0
            ? (_selection.getSelection()[0] as microsoftGraph.DriveItem)
            : undefined;
        setSelectedItem(currentSelectedItem);
      },
    });
  }, [setSelectedItem]);

  function renderFileType(item: microsoftGraph.DriveItem) {
    if (item.name?.endsWith(".encrypt")) {
      return <FontIcon iconName="encrypt-svg" className="fileIconImg" />;
    }

    let iconOptions: IFileTypeIconOptions = { imageFileType: "svg", size: 16 };
    if (item.folder) {
      iconOptions.type = FileIconType.folder;
    } else if (item.file?.mimeType) {
      iconOptions.extension = mime.extension(item.file.mimeType) || "txt";
    } else if (item.package) {
      if (item.package.type === "oneNote") {
        iconOptions.extension = "one";
      }
    }

    return <Icon {...getFileTypeIconProps(iconOptions)} className="fileIconImg" />;
  }

  function renderFileName(item: microsoftGraph.DriveItem) {
    return (
      <div>
        {!!item.folder && (
          <RouterLink
            to={`/tabs/${driveType}?driveid=${item.parentReference?.driveId}&folderid=${item.id}`}
            className="folderLink"
          >
            {item.name}
          </RouterLink>
        )}
        {!item.folder && !item.name?.endsWith(".encrypt") && (
          <Link href={item.webUrl || ""} target="_blank" className="fileLink">
            {item.name}
          </Link>
        )}
        {!item.folder && item.name?.endsWith(".encrypt") && (
          <Link className="fileLink" onClick={() => viewEncryptedFile(item.id || "")}>
            {item.name}
          </Link>
        )}
      </div>
    );
  }

  function renderDate(item: microsoftGraph.DriveItem) {
    const date = item.lastModifiedDateTime;
    const locale = appContext.teamsContext?.locale;
    return date ? new Date(date).toLocaleString(locale) : "";
  }

  function renderIdentityName(item: microsoftGraph.DriveItem) {
    const modifiedBy = item.lastModifiedBy;
    if (modifiedBy) {
      if (modifiedBy.user) {
        return modifiedBy.user.displayName;
      } else if (modifiedBy.device) {
        return modifiedBy.device.displayName;
      } else if (modifiedBy.application) {
        return modifiedBy.application.displayName;
      }
    }

    return "";
  }

  function renderSize(item: microsoftGraph.DriveItem) {
    if (item.folder) {
      return t("List.FilesCount", { count: item.folder.childCount || 0 });
    }
    if (item.size) {
      return filesize(item.size);
    }
    return "";
  }

  function renderShared(item: microsoftGraph.DriveItem) {
    if (item.shared) {
      return (
        <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
          <FontIcon iconName="People" />
          <span>{t("List.Shared")}</span>
        </Stack>
      );
    }

    return t("List.Private");
  }

  function handleColumnHeaderClick(
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    column?: IColumn | undefined
  ) {
    if (column && items) {
      let isSortedDescending = column.isSortedDescending;
      if (column.isSorted) {
        isSortedDescending = !isSortedDescending;
      }

      const sortedItems: microsoftGraph.DriveItem[] = copyAndSort(items, column.fieldName!, isSortedDescending);

      const sortedColumns = columns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      });
      // Reset the items and columns to match the state.
      setItems(sortedItems);
      setColumns(sortedColumns);
    }
  }

  function handleRenderItemColumn(
    item?: microsoftGraph.Drive,
    index?: number | undefined,
    column?: IColumn | undefined
  ) {
    if (item && column) {
      const fieldContent = item[column.fieldName as keyof microsoftGraph.Drive] as string;

      switch (column.key) {
        case "colIcon":
          return renderFileType(item);
        case "colName":
          return renderFileName(item);
        case "colModified":
          return renderDate(item);
        case "colModifiedBy":
          return renderIdentityName(item);
        case "colSize":
          return renderSize(item);
        case "colShared":
          return renderShared(item);
        default:
          return <span>{fieldContent}</span>;
      }
    }
    return "";
  }

  function copyAndSort(
    items: microsoftGraph.DriveItem[],
    columnKey: string,
    isSortedDescending?: boolean
  ): microsoftGraph.DriveItem[] {
    const key = columnKey as keyof microsoftGraph.DriveItem;

    switch (key) {
      case "name":
        return (isSortedDescending ? callbackItems()?.reverse() : callbackItems()) || [];
      case "lastModifiedBy":
        return items.slice(0).sort((a: microsoftGraph.DriveItem, b: microsoftGraph.DriveItem) => {
          const nameA = a.lastModifiedBy?.user || a.lastModifiedBy?.application || a.lastModifiedBy?.device;
          const nameB = b.lastModifiedBy?.user || b.lastModifiedBy?.application || b.lastModifiedBy?.device;
          return (
            isSortedDescending
              ? (nameA?.displayName || "") < (nameB?.displayName || "")
              : (nameA?.displayName || "") > (nameB?.displayName || "")
          )
            ? 1
            : -1;
        });
      case "size":
        return items.slice(0).sort((a: microsoftGraph.DriveItem, b: microsoftGraph.DriveItem) => {
          if (!!a.folder && !b.folder) {
            return 1;
          } else if (!a.folder && !!b.folder) {
            return -1;
          } else if (!!a.folder && !!b.folder) {
            return (
              isSortedDescending
                ? (a.folder.childCount || 0) < (b.folder.childCount || 0)
                : (a.folder.childCount || 0) > (b.folder.childCount || 0)
            )
              ? 1
              : -1;
          } else if (a.size && b.size) {
            return (isSortedDescending ? a.size < b.size : a.size > b.size) ? 1 : -1;
          }
          return 0;
        });
      case "shared":
        return items
          .slice(0)
          .sort((a: microsoftGraph.DriveItem, b: microsoftGraph.DriveItem) =>
            (isSortedDescending ? !!a.shared < !!b.shared : !!a.shared > !!b.shared) ? 1 : -1
          );
      default:
        return items
          .slice(0)
          .sort((a: microsoftGraph.DriveItem, b: microsoftGraph.DriveItem) =>
            (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
          );
    }
  }

  function viewEncryptedFile(fileId: string) {
    setHideDecryptDialog(false);
    setFileIdToDecrypt(fileId);
  }

  function downloadEncryptedFile() {
    if (appContext.currentUser && appContext.teamsContext && appContext.drive?.id && fileIdToDecrypt) {
      console.log('downloadEncryptedFile');
      setGettingFile(true);

      const fileApi = new DifensoTeamsApi("api");
      fileApi.getFile(appContext.drive?.id, fileIdToDecrypt).then((response) => {
        const contentType = response.headers["content-type"];
        const contentDisposition: string = response.headers["content-disposition"];
        const filenameInfo = contentDisposition.split(";").find((i) => i.indexOf("filename=") > -1);
        if (filenameInfo) {
          const filename = filenameInfo.trim().replace("filename=", "").replace(/"/g, "");
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
          link.href = url;

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          onDownloadedFile(filename);

          closeDecryptDialog();
        }
      }).catch((reason) => {
        if (reason.response.status === 401) {
          // User has no permission to decrypt the file, ask for permissions to the owner
          // It will open the approval dialog
          setFileIdToApprove(fileIdToDecrypt);
        }
        else {
          if (reason.response.data) {
            fetch(URL.createObjectURL(reason.response.data)).then(res => res.arrayBuffer()).then((buffer) => {
              const res = Utils.arrayBufferToBase64(buffer);
              const err = atob(res);
              if (err.startsWith('{')) {
                const errDetails = JSON.parse(err);
                if (errDetails.errorCode && errDetails.message) {
                  setErrorMessage(`${errDetails.message} (${errDetails.errorCode})`);
                }
              }
              else {
                setErrorMessage(t("Error.ApprovalRequest"));
              }
            });
          }
        }
        closeDecryptDialog();
      });
    } else {
      closeDecryptDialog();
    }
  }

  const downloadEncryptedFileOnMobile = useCallback(() => {
    if (appContext.currentUser && appContext.teamsContext && appContext.drive?.id && fileIdToDecrypt) {
      console.log('downloadEncryptedFileOnMobile');
      setGettingFile(true);

      const fileApi = new DifensoTeamsApi("api");
      fileApi.getFileOneTimeToken(appContext.drive?.id || '', fileIdToDecrypt).then((ottk) => {
        const fileUrl = fileApi.getFileUrl(appContext.drive?.id || '', fileIdToDecrypt) + "/" + ottk.data.token;
        console.log(fileUrl);
        microsoftTeams.executeDeepLink(fileUrl);
      })
      .catch((reason) => {
        if (reason.response.status === 401) {
          // User has no permission to decrypt the file, ask for permissions to the owner
          // It will open the approval dialog
          setFileIdToApprove(fileIdToDecrypt);
        }
        else {
          setErrorMessage(t("Error.ApprovalRequest"));
        }
      })
      .finally(() => {
        setGettingFile(false);
        closeDecryptDialog();
      });
    }
  }, [appContext, fileIdToDecrypt, setErrorMessage, t]);

  function onDialogClosed() {
    setFileIdToApprove(undefined);
  }

  function closeDecryptDialog() {
    setHideDecryptDialog(true);
    setFileIdToDecrypt(undefined);
    setGettingFile(false);
  }

  return (
    <div hidden={items && items?.length === 0}>
      <ShimmeredDetailsList
        compact={isMobile}
        items={items || []}
        columns={columns}
        enableShimmer={items === undefined}
        selectionMode={SelectionMode.single}
        selection={_selection}
        shimmerLines={15}
        onColumnHeaderClick={handleColumnHeaderClick}
        onRenderItemColumn={handleRenderItemColumn}
      />
      <Dialog hidden={hideDecryptDialog} onDismiss={closeDecryptDialog} dialogContentProps={{ title: t('DecryptDialog.Title'), type: DialogType.normal }}>
        <div className="decryptDialogText">{t("DecryptDialog.Text")}</div>
        <div className="decryptDialogSubText">{isMobile ? t("DecryptDialog.SubTextOnMobile") : t("DecryptDialog.SubText")}</div>
        <DialogFooter>
          <DefaultButton text={t("DecryptDialog.Cancel")} onClick={closeDecryptDialog} />
          {!gettingFile && (
            <PrimaryButton text={t("DecryptDialog.Decrypt")} onClick={isMobile ? downloadEncryptedFileOnMobile : downloadEncryptedFile} className="primaryButton" />
          )}
          {gettingFile && (
            <Spinner size={SpinnerSize.small} styles={{ root: [{ display: 'inline-flex' }] }} />
          )}
        </DialogFooter>
      </Dialog>
      <ApprovalRequestDialog fileId={fileIdToApprove} onDialogClosed={onDialogClosed} />
    </div>
  );
};

export default DriveItemsList;
