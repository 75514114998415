import BaseApi from "./BaseApi";
import IUser from "../models/IUser";

/**
 * The Difenso personnal API class.
 *
 * @class
 */
class DifensoPersonalApi extends BaseApi {
    /**
     * Get the personal informations.
     *
     * @return {Promise} The request promise.
     */
    public getMe = async (dwsPassword?: string): Promise<IUser> => {
        const res = await this._get(`me?dwsPassword=${encodeURIComponent(dwsPassword || '')}`, { });
        return res.data;
    };

    /**
     * Get the redirect uri
     *
     * @return {Promise} The request promise.
     */
    public getAuthenticationRedirectUri = async (): Promise<string> => {
        const res = await this._get(`authredirect`, {});
        return res.data;
    };
}

export default DifensoPersonalApi;
