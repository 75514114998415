import React, {useEffect, useState, useContext, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";
import {Spinner, SpinnerSize, Stack, PrimaryButton, Image} from "office-ui-fabric-react";
import DriveApi from "../../api/DriveApi";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import DriveItemsList from "../../components/DriveItemsList/DriveItemsList";
import DriveBreadcrumb from "../../components/DriveBreadcrumb/DriveBreadcrumb";
import DriveMenu from "../../components/DriveMenu/DriveMenu";
import AppContext from "../../contexts/AppContext";
import {DriveType} from "../../common/DriveType";
import dropFileImage from "../../images/dropfile.svg";
import "./styles.scss";
import {useTranslation} from "react-i18next";
import PostFileResult from "../../models/PostFileResult";
import {MessageBar, MessageBarType} from "@fluentui/react";

const pageSize = 200;

export interface DriveProps {
  DriveType: DriveType;
}

const Drive: React.FC<DriveProps> = (props: DriveProps) => {
  const [isVisualize, setisVisualize] = useState(false);
  const [needRefresh, setNeedRefresh] = useState<boolean>(false);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [driveId, setDriveId] = useState<string>("");
  const [folderId, setFolderId] = useState<string>("");
  const [folderPath, setFolderPath] = useState<string>("");
  const [folderInfo, setFolderInfo] = useState<microsoftGraph.DriveItem>();
  const [driveItems, setDriveItems] = useState<microsoftGraph.DriveItem[] | undefined>(undefined);
  const [filterText, setFilterText] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<microsoftGraph.DriveItem | undefined>(undefined);
  const [uploadingFilenames, setUploadingFilenames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [informationMessage, setInformationMessage] = useState<string>();
  const [downloadedFile, setDownloadedFile] = useState<string>();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const {t} = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (appContext.currentUser) {
        setErrorMessage(undefined);
        setInformationMessage(undefined);
        const fileApi = new DifensoTeamsApi("api");

        const requests: Promise<PostFileResult>[] = [];
        setUploadingFilenames(acceptedFiles.map((file) => file.name));
        acceptedFiles.forEach(async (file) => {
          requests.push(fileApi.postFile(file, driveId, folderId));
        });

        Promise.all(requests)
          .then((results) => {
            setNeedRefresh(true);
            setUploadingFilenames([]);
            const clearRes = results.filter((r) => r.clearTotal > 0);
            setInformationMessage(
              t(
                `Information.Upload${results.length > 1 ? "Multiple" : "Single"}${
                  clearRes.length > 0 ? "WithClear" : ""
                }Success`
              )
            );
          })
          .catch((reason) => {
            if (reason?.response?.status === 403) {
              setErrorMessage(t("Error.FileReadOnly"));
            } else {
              setErrorMessage(t("Error.Upload"));
            }
            setUploadingFilenames([]);
          });
      }
    },
    [appContext.currentUser, driveId, folderId, t]
  );

  const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (appContext.currentUser != null) {
      // update info when location changes
      let query = new URLSearchParams(location.search);
      setDriveId(query.get("driveid") || "");
      setFolderId(query.get("folderid") || "");
      setFolderPath(query.get("folderpath") || "");
      setDriveItems(undefined);
      setNeedRefresh(true);
    }
  }, [location, appContext]);

  useEffect(() => {
    if (needRefresh && appContext.currentUser != null) {
      let scope = "";
      switch (props.DriveType) {
        case "onedrive":
          scope = "me/drive";
          break;
        case "sharepoint":
        case "teams":
          scope = `drives/${driveId}`;
          break;
        default:
          break;
      }

      // get all data
      const driveApi = new DriveApi(scope, appContext.currentUser.accessToken);
      if (!appContext.drive) {
        driveApi
          .getDrive()
          .then((result) => {
            appContext.setDrive(result);
            setDriveId(result.id || "");
          })
          .catch((reason) => {
            if (reason && reason.response) {
              console.log(reason.response.status);
            }
            setAccessDenied(true);
          });
      }
      // item can be retrieved by id or by path
      if (folderId) {
        driveApi.getDriveItem(folderId).then(setFolderInfo);
        driveApi.getDriveItems(folderId, pageSize).then(setDriveItems);
      } else {
        driveApi.getDriveItemByPath(folderPath).then((result) => {
          setFolderInfo(result);
          setFolderId(result.id || "");
        });
        driveApi.getDriveItemsByPath(folderPath, pageSize).then(setDriveItems);
      }
      setNeedRefresh(false);
    }
  }, [needRefresh, driveId, folderId, folderPath, appContext, driveItems, props.DriveType]);

  const getFilteredItems = useCallback(() => {
    return driveItems?.filter((item) => item.name?.toLowerCase().includes(filterText.toLowerCase()));
  }, [driveItems, filterText]);

  function clickRefreshButton() {
    setNeedRefresh(true);
    setDriveItems(undefined);
    setErrorMessage(undefined);
    setInformationMessage(undefined);
  }

  const toggleVisualize = () => {
    setisVisualize(!isVisualize);
  };

  function clickVisualizeButton() {
    toggleVisualize();
  }

  function clickDeleteButton() {
    if (appContext.currentUser != null) {
      let scope = "";
      switch (props.DriveType) {
        case "onedrive":
          scope = "me/drive";
          break;
        case "sharepoint":
        case "teams":
          scope = `drives/${driveId}`;
          break;
        default:
          break;
      }

      // get all data
      const driveApi = new DriveApi(scope, appContext.currentUser.accessToken);
      driveApi.delete(driveId, selectedItem?.id!).then((res) => {
        clickRefreshButton();
      });
    }
  }

  function onDownloaded(filename: string) {
    setDownloadedFile(filename);
  }

  return (
    <div>
      {accessDenied && (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{root: [{height: "100vh"}]}}>
          {t("Error.AccessDenied")}
        </Stack>
      )}
      {!accessDenied && !appContext.drive && (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{root: [{height: "100vh"}]}}>
          <Spinner size={SpinnerSize.large} styles={{circle: [{borderColor: "#6264a7 #e0e0ed #e0e0ed"}]}}></Spinner>
        </Stack>
      )}
      {!accessDenied && appContext.drive && (
        <div {...getRootProps({className: "dropzone"})}>
          <input {...getInputProps()} />
          {isDragActive && driveItems && driveItems?.length > 0 && (
            <div className="dragBackground">
              <div className="dragBackgroundInner">
                <div className="dragBackgroundContent">
                  <Image src={dropFileImage} />
                  <div className="dragBackgroundContentText">{t("Drop.Text")}</div>
                </div>
              </div>
            </div>
          )}
          <Stack className="driveContent">
            <div className="driveHeader">
              <DriveMenu
                updateFilter={setFilterText}
                clickUploadButton={open}
                clickRefreshButton={clickRefreshButton}
                clickVisualizeButton={clickVisualizeButton}
                clickDeleteButton={clickDeleteButton}
                selectedItem={selectedItem}
                uploadingFilenames={uploadingFilenames}
                errorMessage={errorMessage}
                informationMessage={informationMessage}
              ></DriveMenu>
              <DriveBreadcrumb
                driveType={props.DriveType}
                folderPath={folderInfo?.webUrl?.replace(appContext.drive.webUrl || "", "") || ""}
              />
            </div>
            {downloadedFile && (
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={() => setDownloadedFile(undefined)}
                dismissButtonAriaLabel={t("Menu.Close")}
              >
                {t("Information.DownloadedFileSuccess")} (<b>{downloadedFile}</b>)
              </MessageBar>
            )}
            <DriveItemsList
              driveType={props.DriveType}
              callbackItems={getFilteredItems}
              setSelectedItem={setSelectedItem}
              setErrorMessage={setErrorMessage}
              onDownloadedFile={onDownloaded}
            />
            {!driveItems ||
              (driveItems && driveItems?.length === 0 && (
                <Stack grow className="containerNoFile" horizontal>
                  <Stack grow className="containerNoFileInner" horizontalAlign="center" verticalAlign="center">
                    <div className="dragBackgroundContent">
                      <Image src={dropFileImage} />
                      <div className="dragBackgroundContentText">{t("Drop.Text")}</div>
                      <div className="dragBackgroundContentSubText">{t("Drop.SubText")}</div>
                      <PrimaryButton
                        text={t("Drop.Upload")}
                        onClick={open}
                        className="primaryButton"
                        styles={{root: [{marginTop: 15}]}}
                      />
                    </div>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </div>
      )}
    </div>
  );
};

export default Drive;
