import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Image, Skeleton, Text, Checkbox, Input, Dialog, SkeletonButton, Alert } from "@fluentui/react-northstar";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from 'uuid';
import AppContext from "../../contexts/AppContext";
import dropFileImage from "../../images/dropfile.svg";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js"; // optional ( for default css style )
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import logoVisa from "../../images/logo-visa.png";
import logoDifenso from "../../images/logo_difenso.png";

const delimiters = ['Comma', 'Enter', 'Semicolon', 'Tab'];

export interface SecureMailDesktopWebProps {
  sendMail: () => void;
  handleRecipientToDelete: (i: number) => void;
  handleRecipientToAddition: (recipient: Tag) => void;
  handleRecipientCcDelete: (i: number) => void;
  handleRecipientCcAddition: (recipient: Tag) => void;
  handleRecipientBccDelete: (i: number) => void;
  handleRecipientBccAddition: (recipient: Tag) => void;
  handleFilterSuggestions: (textInputValue: string, possibleSuggestions: Tag[]) => Tag[];
  generateSuggestions: (text: string) => void;
  handleValidation: (tag: Tag) => boolean;
  subject: string | undefined;
  setSubject: React.Dispatch<React.SetStateAction<string | undefined>>;
  body: string | undefined;
  setBody: React.Dispatch<React.SetStateAction<string | undefined>>;
  suggestions: Tag[] | undefined;
  recipientsTo: Tag[];
  recipientsCc: Tag[];
  recipientsBcc: Tag[];
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  encryptBody: boolean | undefined;
  setEncryptBody: React.Dispatch<React.SetStateAction<boolean>>;
  encryptAttachments: boolean | undefined;
  setEncryptAttachments: React.Dispatch<React.SetStateAction<boolean>>;
  sendingMessage: boolean;
  successMessage: string | undefined;
  errorMessage: string | undefined;
}

const SecureMailDesktopWeb = (props: SecureMailDesktopWebProps) => {
  const {
    sendMail,
    handleRecipientToDelete,
    handleRecipientToAddition,
    handleRecipientCcDelete,
    handleRecipientCcAddition,
    handleRecipientBccDelete,
    handleRecipientBccAddition,
    handleFilterSuggestions,
    generateSuggestions,
    handleValidation,
    subject,
    setSubject,
    body,
    setBody,
    suggestions,
    recipientsTo,
    recipientsCc,
    recipientsBcc,
    attachments,
    setAttachments,
    encryptBody,
    setEncryptBody,
    encryptAttachments,
    setEncryptAttachments,
    sendingMessage,
    successMessage,
    errorMessage,
  } = props;
  const { currentUser } = useContext(AppContext);
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const updateTextDescription = async (state: EditorState) => {
    await setEditorState(state);
    const data = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(data);
    setBody(html);
  };

  // reset body
  useEffect(() => {
    if (!body || body.trim() === '') {
      setEditorState(EditorState.createEmpty());
    }
  }, [body]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (currentUser) {
        setAttachments([...attachments, ...acceptedFiles]);
      }
    },
    [currentUser, attachments, setAttachments]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const handleAttachmentDelete = (i: number) => {
    if (!sendingMessage) {
      setAttachments(attachments.filter((r, idx) => idx !== i));
    }
  }

  return (
    <Flex column gap="gap.medium" style={{ height: '95vh', padding: '10px 30px 0 30px', overflowX: 'hidden' }} className="secured-mail-container">
      <Flex.Item>
        <Flex gap="gap.medium" hAlign="start">
          <Text
            content={t("SecureMail.Description")}
            size="large" style={{ marginTop: 10 }} />
          <Flex.Item push>
            <a href="https://www.difenso.com/" rel="noopener noreferrer" target="_blank">
              <Image src={logoDifenso} alt="Difenso" styles={{ root: { marginRight: 24 } }} />
            </a>
          </Flex.Item>
          <a href="https://www.ssi.gouv.fr/entreprise/certification_cspn/difenso-core-system/" rel="noopener noreferrer" target="_blank">
            <Image src={logoVisa} alt="ANSSI" styles={{ root: { marginRight: 24 }, image: { width: 'auto' } }} />
          </a>
        </Flex>
      </Flex.Item>
      <Flex.Item grow>
        <Flex column fill gap="gap.medium" style={{ overflowY: 'auto' }}>
          {!currentUser && (
            <Skeleton animation="wave">
              <Skeleton.Line width="100%" height="50px" style={{ marginBottom: '10px' }} />
              <Skeleton.Line width="100%" height="50px" style={{ marginBottom: '10px' }} />
              <Skeleton.Line width="100%" height="50px" style={{ marginBottom: '10px' }} />
              <Skeleton.Line width="100%" height="50px" style={{ marginBottom: '10px' }} />
              <Skeleton.Line width="100%" height="200px" style={{ marginBottom: '10px' }} />
              <Skeleton.Line width="100%" height="200px" style={{ marginBottom: '10px' }} />
            </Skeleton>
          )}
          {currentUser && (<>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Text
                      content={`${t("SecureMail.Subject")} *`}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td><Input
                    key="subject"
                    fluid
                    inverted
                    disabled={sendingMessage}
                    className="securemail-subject"
                    value={subject}
                    defaultValue=""
                    onChange={(ev, data) => setSubject(data?.value)}
                  /></td>
                </tr>
                <tr>
                  <td>
                    <Text
                      content={`${t("SecureMail.RecipientsTo")} *`}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td className="bottom">
                    {sendingMessage ? recipientsTo.map((r) => <span key={r.id}>{r.name}</span>) : (
                      <ReactTags
                        id="recipientsTo"
                        key="recipientsTo"
                        tags={recipientsTo}
                        minQueryLength={2}
                        suggestions={suggestions}
                        suggestionsTransform={handleFilterSuggestions}
                        onDelete={handleRecipientToDelete}
                        onAddition={handleRecipientToAddition}
                        onValidate={handleValidation}
                        onInput={generateSuggestions}
                        placeholderText={t("SecureMail.AddRecipient")}
                        noSuggestionsText={t("SecureMail.NoRecipient")}
                        removeButtonText={t("SecureMail.RemoveRecipient")}
                        delimiters={delimiters} />)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      content={t("SecureMail.RecipientsCc")}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td className="bottom">
                    {sendingMessage ? recipientsCc.map((r) => <span key={r.id}>{r.name}</span>) : (
                      <ReactTags
                        id="recipientsCc"
                        key="recipientsCc"
                        tags={recipientsCc}
                        minQueryLength={2}
                        suggestions={suggestions}
                        suggestionsTransform={handleFilterSuggestions}
                        onDelete={handleRecipientCcDelete}
                        onAddition={handleRecipientCcAddition}
                        onValidate={handleValidation}
                        onInput={generateSuggestions}
                        placeholderText={t("SecureMail.AddRecipient")}
                        noSuggestionsText={t("SecureMail.NoRecipient")}
                        removeButtonText={t("SecureMail.RemoveRecipient")}
                        delimiters={delimiters} />)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      content={t("SecureMail.RecipientsBcc")}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td className="bottom">
                    {sendingMessage ? recipientsBcc.map((r) => <span key={r.id}>{r.name}</span>) : (
                      <ReactTags
                        id="recipientsBcc"
                        key="recipientsBcc"
                        tags={recipientsBcc}
                        minQueryLength={2}
                        suggestions={suggestions}
                        suggestionsTransform={handleFilterSuggestions}
                        onDelete={handleRecipientBccDelete}
                        onAddition={handleRecipientBccAddition}
                        onValidate={handleValidation}
                        onInput={generateSuggestions}
                        placeholderText={t("SecureMail.AddRecipient")}
                        noSuggestionsText={t("SecureMail.NoRecipient")}
                        removeButtonText={t("SecureMail.RemoveRecipient")}
                        delimiters={delimiters} />)}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'text-top', marginTop: '3px' }}>
                    <Text
                      content={t("SecureMail.Body")}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="securemail-toolbar"
                      wrapperClassName="securemail-wrapper"
                      editorClassName="securemail-editor"
                      readOnly={sendingMessage}
                      toolbarHidden={sendingMessage}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                      }}
                      onEditorStateChange={updateTextDescription}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'text-top', marginTop: '3px' }}>
                    <Text content={t("SecureMail.Attachments")}
                      size="medium"
                      weight="bold" />
                  </td>
                  <td>
                    <Flex fill className="containerSecuredMail" column>
                      {!sendingMessage && (
                        <Flex.Item grow className="containerSecuredMailInner">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="dragBackgroundContent">
                              <Image src={dropFileImage} style={{ maxHeight: '80px' }} />
                              <div className="dragBackgroundContentText">{t("Drop.Text")}</div>
                              <div className="dragBackgroundContentSubText">{t("Drop.SubText")}</div>
                              <Button
                                primary
                                content={t("Drop.Upload")}
                                onClick={() => open()}
                                styles={{ root: [{ marginTop: '15px' }] }}
                              />
                            </div>
                          </div>
                        </Flex.Item>
                      )}
                      {attachments.length > 0 && (
                        <div
                          style={{ marginTop: 10 }}>
                          <ReactTags
                            id="attachments"
                            key="attachments"
                            allowNew={false}
                            tags={attachments.map((att) => { return { id: uuidv4(), name: att.name, disabled: true } })}
                            onDelete={handleAttachmentDelete}
                            onAddition={() => { }}
                            onValidate={handleValidation}
                            delimiters={delimiters} />
                        </div>
                      )}
                    </Flex>
                  </td>
                </tr>
              </tbody>
            </table>
          </>)}
        </Flex>
      </Flex.Item>
      <Flex.Item>
        <Flex gap="gap.medium" hAlign="end">
          {currentUser && (<>
            <Checkbox label={t("SecureMail.EncryptBody")} toggle disabled={sendingMessage} defaultChecked={encryptBody} onChange={(e, data) => { setEncryptBody(data?.checked !== undefined ? data.checked : false) }} />
            <Checkbox label={t("SecureMail.EncryptAttachments")} toggle disabled={sendingMessage} defaultChecked={encryptAttachments} onChange={(e, data) => { setEncryptAttachments(data?.checked !== undefined ? data?.checked : false) }} />
            <Dialog
              cancelButton={t("SecureMail.Cancel")}
              confirmButton={t("SecureMail.Send")}
              content={t("SecureMail.ConfirmBody")}
              onConfirm={sendMail}
              header={t("SecureMail.ConfirmTitle")}
              trigger={<Button style={{ marginLeft: 10 }} primary loading={sendingMessage} disabled={sendingMessage || !subject || subject.length === 0 || !recipientsTo || recipientsTo.length === 0} content={t("SecureMail.Send")} />}
            />
          </>)}
          {!currentUser && (
            <Flex hAlign="end">
              <SkeletonButton width="100px" style={{ margin: '10px 0' }} />
            </Flex>
          )}
        </Flex>
      </Flex.Item>
      {(successMessage) && (<Alert
        success
        content={t(successMessage)}
        dismissible
        dismissAction={{
          'aria-label': 'close',
        }}
      />)}
      {(errorMessage) && (<Alert
        danger
        content={t(errorMessage)}
        dismissible
        dismissAction={{
          'aria-label': 'close',
        }}
      />)}
    </Flex >
  );
};

export default SecureMailDesktopWeb;
