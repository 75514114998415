import React from "react";
import DifensoPersonalApi from "../../api/DifensoPersonalApi";
import * as microsoftTeams from "@microsoft/teams-js";
import { Spinner, SpinnerSize } from "@fluentui/react";

export interface ConsentProps {
  action: 'start' | 'finish';
}

const Consent: React.FC<ConsentProps> = (props: ConsentProps) => {
  microsoftTeams.initialize();

  if (props.action === 'start'){    
    const personalApi = new DifensoPersonalApi("api", '', window.location.origin);
    personalApi.getAuthenticationRedirectUri().then((url) => {
      document.location.assign(url);
    }).catch((reason) => {
      microsoftTeams.authentication.notifyFailure(reason);
    });
  }
  else if (props.action === 'finish') {
    microsoftTeams.authentication.notifySuccess('ok');
  }

  return (
    <div>
      <Spinner size={SpinnerSize.large}></Spinner>
    </div>
  );
};

export default Consent;
