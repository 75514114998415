import React, { useCallback, useContext, useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import AppContext from "../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import { DecryptedChatFileMessage } from "../../models/DecryptedChatMessage";
import { Button, Flex, Loader, Skeleton, Segment, TextArea, Text } from "@fluentui/react-northstar";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "@fluentui/react";

const delimiters = ["Comma", "Enter", "Semicolon", "Tab"];
const b64prefix = ";base64,";

export interface SecureChatMessageProps {
  action: "encrypt" | "decrypt";
}

const SecureChatMessage = (props: SecureChatMessageProps) => {
  const { currentUser } = useContext(AppContext);
  const { t } = useTranslation();

  const [content, setContent] = useState<string>();
  const [userIP, setUserIP] = useState<string>();
  const [decryptedText, setDecryptedText] = useState<string>();
  const [decryptedAttachments, setDecryptedAttachments] = useState<DecryptedChatFileMessage[]>();
  const [decryptError, setDecryptError] = useState<string>();
  const [recipients, setRecipients] = useState<Tag[]>([]);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Tag[]>();
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const [decrypting, setDecrypting] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<File[]>([]);

  const uParams = new URLSearchParams(document.location.search);
  const chatMessageId = uParams.get("msgId");
  //const { action } = props;
  const action: "encrypt" | "decrypt" = uParams.get("decrypt") === "1" ? "decrypt" : props.action;

  const sendChatMessageToEncrypt = useCallback(() => {
    if (!sendingMessage) {
      setSendingMessage(true);

      var proms = attachments.map((a) => {
        return toBase64(a).then((b64) => {
          const idx = b64.indexOf(b64prefix);
          if (idx > 0) b64 = b64.substring(idx + b64prefix.length);
          return {
            Filename: a.name,
            Content: b64,
          };
        });
      });

      Promise.all(proms).then((filesInB64) => {
        microsoftTeams.tasks.submitTask({
          Action: "EncryptChatMessage",
          Content: content,
          Files: filesInB64,
          Addresses: recipients.map((r) => r.id),
          UserOptions: {
            UserName: currentUser?.username,
            Password: currentUser?.dwsPassword,
            UserIP: userIP,
          },
        });
      });
    }
  }, [sendingMessage, content, recipients, currentUser, userIP, attachments]);

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const updateChatMessageAuthorizations = useCallback(() => {
    if (!sendingMessage) {
      setSendingMessage(true);
      microsoftTeams.tasks.submitTask({
        Action: "UpdateAuthorizationList",
        KeyId: chatMessageId,
        Addresses: recipients.map((r) => r.id),
        UserOptions: {
          UserName: currentUser?.username,
          Password: currentUser?.dwsPassword,
          UserIP: userIP,
        },
      });
    }
  }, [sendingMessage, chatMessageId, recipients, currentUser, userIP]);

  const close = () => {
    microsoftTeams.tasks.submitTask();
  };

  const handleRecipientDelete = (i: number) => {
    setRecipients(recipients.filter((r, idx) => idx !== i));
  };

  const handleRecipientAddition = (recipient: Tag) => {
    // Change it to keep only email
    setRecipients([...recipients, { id: recipient.id, name: recipient.id.toString() }]);
  };

  const handleFilterSuggestions = (textInputValue: string, possibleSuggestions: Tag[]): Tag[] => {
    if (textInputValue && (!possibleSuggestions || possibleSuggestions.length === 0)) {
      const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(textInputValue);
      if (isValidEmail) {
        return [{ id: textInputValue, name: textInputValue }];
      } else {
        return [];
      }
    }
    return possibleSuggestions;
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (currentUser) {
        setAttachments([...attachments, ...acceptedFiles]);
      }
    },
    [currentUser, attachments, setAttachments]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const generateSuggestions = (text: string) => {
    if (text && text.length >= 2) {
      const tApi = new DifensoTeamsApi("api");
      tApi.getUsers(text).then((users) => {
        const sugg = users.data.map((u) => {
          return { id: u.mail, name: `${u.displayName} (${u.mail})` };
        });
        setSuggestions(sugg);
      });
    }
  };

  const handleValidation = (tag: Tag) => {
    setSuggestions([]);
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_${{|}~-]+@[a-} (${u.mail})`zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      tag.id.toString()
    );
  };

  const handleAttachmentDelete = (i: number) => {
    if (!sendingMessage) {
      setAttachments(attachments.filter((r, idx) => idx !== i));
    }
  };

  useEffect(() => {
    if (!userIP) {
      const tApi = new DifensoTeamsApi("api");
      tApi.getUserIP().then((uIP) => {
        setUserIP(uIP.data);
      });
    }
  }, [userIP]);

  useEffect(() => {
    if (action === "decrypt" && chatMessageId && currentUser && decrypting) {
      // try to decrypt
      const tApi = new DifensoTeamsApi("api");
      tApi
        .decryptChatMessage(chatMessageId)
        .then((result) => {
          setDecryptedText(result.data.content);
          setDecryptedAttachments(result.data.files);
          if (result.data.authorizedUsers) {
            const rec = result.data.authorizedUsers.map((r) => {
              return { id: r, name: r };
            });
            setCanUpdate(rec.length > 0);
            setRecipients(rec);
          }
        })
        .catch((reason) => {
          if (reason.response && reason.response.status === 403) {
            setDecryptError("Error.ChatMessageAccessDenied");
          } else {
            setDecryptError("Error.ChatMessageNotFound");
          }
        })
        .finally(() => {
          setDecrypting(false);
        });
    }
  }, [action, currentUser, decrypting, chatMessageId]);

  const downloadAttachment = (fileIdToDecrypt: string) => {
    const fileApi = new DifensoTeamsApi("api");
    fileApi
      .getFileAsync(fileIdToDecrypt)
      .then((response) => {
        const contentType = response.headers["content-type"];
        const contentDisposition: string = response.headers["content-disposition"];
        const filenameInfo = contentDisposition.split(";").find((i) => i.indexOf("filename=") > -1);
        if (filenameInfo) {
          const filename = filenameInfo.trim().replace("filename=", "").replace(/"/g, "");
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
          link.href = url;

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((reason) => {
        if (reason.response.status === 401) {
          // User has no permission to decrypt the file, ask for permissions to the owner
          // It will open the approval dialog
        }
      });
  };

  return (
    <Flex column gap="gap.medium" style={{ height: "95vh", padding: "10px 30px 0 30px", overflowX: "hidden" }}>
      <Flex.Item grow>
        <Flex column fill gap="gap.medium">
          {!currentUser && action === "encrypt" && <Loader />}
          {currentUser && action === "encrypt" && (
            <>
              <Text color="brand" content={t("EncryptChatMessage.Content")} size="medium" weight="bold" />
              <Flex.Item grow>
                <TextArea
                  required
                  fluid
                  style={{ height: "70%" }}
                  placeholder={t("EncryptChatMessage.ContentPlaceholder")}
                  value={content}
                  onChange={(ev, data) => setContent(data?.value)}
                ></TextArea>
              </Flex.Item>
              <Flex.Item grow>
                <Stack>
                  <Flex fill className="containerSecuredMail" column>
                    {!sendingMessage && (
                      <Flex.Item grow className="containerSecureChatInner">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <div className="dragBackgroundContent">
                            <div className="dragBackgroundContentText">{t("Drop.Text")}</div>
                            <div className="dragBackgroundContentSubText">{t("Drop.SubText")}</div>
                            <Button
                              primary
                              content={t("Drop.Upload")}
                              onClick={() => open()}
                              styles={{ root: [{ marginTop: "5px" }] }}
                            />
                          </div>
                        </div>
                      </Flex.Item>
                    )}
                    {attachments.length > 0 && (
                      <div style={{ marginTop: 10 }}>
                        <ReactTags
                          id="attachments"
                          key="attachments"
                          allowNew={false}
                          tags={attachments.map((att) => {
                            return { id: uuidv4(), name: att.name, disabled: true };
                          })}
                          onDelete={handleAttachmentDelete}
                          onAddition={() => { }}
                          onValidate={handleValidation}
                          delimiters={delimiters}
                        />
                      </div>
                    )}
                  </Flex>
                </Stack>
              </Flex.Item>
              <Text color="brand" content={t("EncryptChatMessage.Recipients")} size="medium" weight="bold" />
              <ReactTags
                key="createRecipients"
                tags={recipients}
                minQueryLength={2}
                suggestions={suggestions}
                suggestionsTransform={handleFilterSuggestions}
                onDelete={handleRecipientDelete}
                onAddition={handleRecipientAddition}
                onValidate={handleValidation}
                onInput={generateSuggestions}
                placeholderText={t("EncryptChatMessage.AddRecipient")}
                noSuggestionsText={t("EncryptChatMessage.NoRecipient")}
                removeButtonText={t("EncryptChatMessage.RemoveRecipient")}
                delimiters={delimiters}
              />
            </>
          )}
          {currentUser && action === "decrypt" && decrypting && (
            <Segment style={{ height: "100%" }} color="brand">
              <Skeleton animation="wave">
                <Skeleton.Line width="90%" style={{ margin: "10px 0" }} />
                <Skeleton.Line width="50%" style={{ margin: "10px 0" }} />
                <Skeleton.Line width="80%" style={{ margin: "10px 0" }} />
                <Skeleton.Line width="50%" style={{ margin: "10px 0" }} />
              </Skeleton>
            </Segment>
          )}
          {currentUser && action === "decrypt" && !decrypting && (
            <>
              {!!decryptError && (
                <Segment style={{ height: "100%" }} color="brand">
                  <p>
                    <Text color="red" content={t(decryptError)}></Text>
                  </p>
                </Segment>
              )}
              {!!decryptedText && <Segment style={{ height: "100%" }} content={decryptedText} />}
              {decryptedAttachments && decryptedAttachments.length > 0 && (
                <>
                  <Text color="brand" content={t("EncryptChatMessage.Attachments")} size="medium" weight="bold" />
                  <div className="secureChatMessageFilesContainer">
                    {decryptedAttachments.map((att, idx) => (
                      <span
                        key={`att-${idx}`}
                        className="attachmentItem"
                        title={t("EncryptChatMessage.Download")}
                        onClick={() => downloadAttachment(att.url)}
                      >
                        <Icon iconName="Download" /> {att.name}
                      </span>
                    ))}
                  </div>
                </>
              )}
              {canUpdate && (
                <>
                  <Text color="brand" content={t("EncryptChatMessage.Recipients")} size="medium" weight="bold" />
                  <ReactTags
                    key="updateRecipients"
                    tags={recipients}
                    minQueryLength={2}
                    suggestions={suggestions}
                    suggestionsTransform={handleFilterSuggestions}
                    onDelete={handleRecipientDelete}
                    onAddition={handleRecipientAddition}
                    onValidate={handleValidation}
                    onInput={generateSuggestions}
                    delimiters={delimiters}
                  />
                </>
              )}
            </>
          )}
        </Flex>
      </Flex.Item>
      <Flex.Item>
        <>
          {currentUser && (
            <Flex gap="gap.medium" vAlign="stretch">
              <Button onClick={close} content={t("EncryptChatMessage.Close")} />
              {action === "encrypt" && (
                <Flex.Item push>
                  <Button
                    onClick={sendChatMessageToEncrypt}
                    primary
                    disabled={
                      sendingMessage || !content || content.length === 0 || !recipients || recipients.length === 0
                    }
                    content={t("EncryptChatMessage.Send")}
                  />
                </Flex.Item>
              )}
              {action === "decrypt" && canUpdate && (
                <Flex.Item push>
                  <Button
                    onClick={updateChatMessageAuthorizations}
                    primary
                    disabled={sendingMessage || !recipients || recipients.length === 0}
                    content={t("EncryptChatMessage.Update")}
                  />
                </Flex.Item>
              )}
            </Flex>
          )}
        </>
      </Flex.Item>
    </Flex>
  );
};

export default SecureChatMessage;
