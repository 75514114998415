import BaseApi from "./BaseApi";
import PostFileResult from "../models/PostFileResult";
import { DecryptedChatMessage } from "../models/DecryptedChatMessage";
import GraphUser from "../models/GraphUser";
import OneTimeToken from "../models/OneTimeToken";
import {AxiosResponse} from "axios";
import {ListAuthorizationsResponse} from "../models/ListAuthorizationsResponse";

/**
 * The Difenso Teams API class.
 *
 * @class
 * @param {string} _bearer The connected user bearer.
 */
class DifensoTeamsApi extends BaseApi {
  constructor(_scope: string) {
    super(_scope, "", window.location.origin);
  }

  /**
   * Get the personal informations.
   *
   * @return {Promise} The request promise.
   */
  public getMe = async (): Promise<any> => {
    const res = await this._get(`me`, {});
    return res;
  };

  /**
   * Find users by name.
   *
   * @return {Promise} The request promise.
   */
  public getUsers = async (name: string): Promise<AxiosResponse<GraphUser[]>> => {
    const res = await this._get(`users/find?name=${encodeURIComponent(name)}`, {});
    return res;
  };

  /**
   * GEt onetimetoken
   *
   * @return {Promise} The request promise.
   */
  public getFileOneTimeToken = async (driveId: string, fileId: string): Promise<AxiosResponse<OneTimeToken>> => {
    return await this._get(`file/${driveId}/${fileId}/ottk`, {});
  };

  /**
   * Get the encrypted file.
   *
   * @return {Promise} The request promise.
   */
  public getFile = async (driveId: string, fileId: string): Promise<any> => {
    const res = await this._get(`file/${driveId}/${fileId}`, {
      timeout: 120000,
      responseType: "blob", // important
    });
    return res;
  };

  /**
   * Delete a file.
   *
   * @return {Promise} The request promise.
   */
  public deleteFile = async (driveId: string, fileId: string): Promise<any> => {
    const res = await this._delete(`file/${driveId}/${fileId}`, {});
    return res;
  };

  /**
   * Update authorisations for a file
   *
   * @return {boolean} Ok
   */
  public updateAuthorizations = (driveId: string, fileId: string, emails: string[]) => {
    let formData = new FormData();
    formData.append("emails", emails.join(";"));

    return this._put(`file/authorizations/${driveId}/${fileId}`, formData);
  };

  /**
   *List authorizations for a file
   *
   * @return {Promise} The file's authorizations
   */
  public listAuthorizationsAsync = async (
    driveId: string,
    fileId: string
  ): Promise<AxiosResponse<ListAuthorizationsResponse>> => {
    const res = await this._get(`file/authorizations/${driveId}/${fileId}`, {});
    return res;
  };

  /**
   * Get the encrypted file's url for direct download.
   *
   * @return {string} The file's url.
   */
  public getFileUrl = async (driveId: string, fileId: string) => {
    return await this._getUrl(`file/${driveId}/${fileId}`);
  };

  /**
   * Get the decrypted chat message from key Id
   *
   * @return {Promise} The request promise.
   */
  public decryptChatMessage = async (chatMessageId: string): Promise<AxiosResponse<DecryptedChatMessage>> => {
    const res = await this._get(`chat/decryptText?msgid=${encodeURIComponent(chatMessageId)}`, {});
    return res;
  };

  /**
   * Post and encrypt the file.
   *
   * @return {Promise} The request promise.
   */
  public postFile = async (file: File, driveId: string, folderId: string): Promise<PostFileResult> => {
    let formData = new FormData();
    formData.append("file", file);

    const headers = {"Content-Type": "multipart/form-data"};
    const res = await this._post(`file/${driveId}/${folderId}/${file.name}`, formData, headers);
    return res.data as PostFileResult;
  };

  /**
   * Request access for the encrypted file.
   *
   * @return {Promise} The request promise.
   */
  public requestAccess = async (
    driveId: string,
    fileId: string,
    source: string,
    context: microsoftTeams.Context
  ): Promise<any> => {
    const res = await this._get(`file/requestaccess/${driveId}/${fileId}`, {
      params: {
        locale: context.locale,
        entityId: context.entityId,
        source: source,
        teamId: context.teamId,
        channelId: context.channelId,
        teamName: context.teamName,
        channelName: context.channelName,
      },
    });
    return res;
  };

  /**
   * Update authorisations for a file
   *
   * @return {Promise} The requested Promise
   */
  public getFileAsync = async (path: string): Promise<any> => {
    return await this._get(`file/getFile`, {
      params: {
        path: path,
      },
      timeout: 120000,
      responseType: "blob", // important
    });
  };

  /**
   * Validate DWS password
   *
   * @return {Promise} The request promise.
   */
  public validateDwsPassword = async (username: string, password: string): Promise<AxiosResponse<boolean>> => {
    const res = await this._get(
      `validate?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      {}
    );
    return res;
  };

  /**
   * Get User IP
   *
   * @return {Promise} The request promise.
   */
  public getUserIP = async (): Promise<AxiosResponse<string>> => {
    const res = await this._get(`myip`, {});
    return res;
  };

  /**
   * Send email
   *
   * @return {Promise} The request promise.
   */
  public sendEmail = async (
    subject: string,
    body: string,
    to: string[],
    cc: string[] | undefined,
    bcc: string[] | undefined,
    attachments: File[] | undefined,
    encryptBody: boolean,
    encryptAttachments: boolean,
    locale: string
  ): Promise<PostFileResult> => {
    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("to", JSON.stringify(to));
    formData.append("cc", JSON.stringify(cc));
    formData.append("bcc", JSON.stringify(bcc));
    formData.append("encryptBody", encryptBody ? "1" : "0");
    formData.append("encryptAttachments", encryptAttachments ? "1" : "0");
    formData.append("locale", locale);

    if (attachments) {
      for (var i = 0; i < attachments.length; i++) formData.append(`file${i}`, attachments[i], attachments[i].name);
    }
    const headers = {"Content-Type": "multipart/form-data"};
    const res = await this._post(`mail/send`, formData, headers);
    return res.data as PostFileResult;
  };
}

export default DifensoTeamsApi;
