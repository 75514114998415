import React from "react";
import {PrimaryButton, Stack, Image} from "@fluentui/react";
import consentImage from "../../images/consent.svg";
import {useTranslation} from "react-i18next";

export interface ConsentRequiredProps {
  launchConsent(): void;
}

const ConsentRequired: React.FC<ConsentRequiredProps> = (props: ConsentRequiredProps) => {
  const {t} = useTranslation();

  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={{root: [{height: "100vh"}]}}>
      <Stack horizontalAlign="center" tokens={{childrenGap: 35}}>
        <Image src={consentImage} />
        <div style={{fontSize: 17, fontWeight: "bold", color: "#333333", letterSpacing: 0.09, textAlign: "center"}}>
          {t("Consent.Text")}
        </div>
        <PrimaryButton text={t("Consent.StartApproval")} onClick={() => props.launchConsent()} className="primaryButton" />
      </Stack>
    </Stack>
  );
};

export default ConsentRequired;
