import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tag } from "react-tag-autocomplete";
import SecureMailMobile from "./SecureMailMobile";
import SecureMailDesktopWeb from "./SecureMailDesktopWeb";
import AppContext from "../../contexts/AppContext";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";

const SecureMail = () => {
  const { teamsContext } = useContext(AppContext);

  const [subject, setSubject] = useState<string>();
  const [body, setBody] = useState<string>();
  const [userIP, setUserIP] = useState<string>();
  const [recipientsTo, setRecipientsTo] = useState<Tag[]>([]);
  const [recipientsCc, setRecipientsCc] = useState<Tag[]>([]);
  const [recipientsBcc, setRecipientsBcc] = useState<Tag[]>([]);
  const [encryptBody, setEncryptBody] = useState<boolean>(true);
  const [encryptAttachments, setEncryptAttachments] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [suggestions, setSuggestions] = useState<Tag[]>();
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const isMobile = teamsContext?.hostClientType === "ios" || teamsContext?.hostClientType === "android";

  const sendMail = useCallback(() => {
    if (subject && recipientsTo && recipientsTo.length > 0 && teamsContext) {
      const mailApi = new DifensoTeamsApi("api");
      setSendingMessage(true);
      setSuccessMessage(undefined);
      setErrorMessage(undefined);
      mailApi.sendEmail(subject, body || '', recipientsTo.map((r) => r.name), recipientsCc?.map((r) => r.name), recipientsBcc?.map((r) => r.name), attachments, encryptBody, encryptAttachments, teamsContext.locale)
      .then(() => {
        setSubject('');
        setBody('');
        setRecipientsTo([]);
        setRecipientsCc([]);
        setRecipientsBcc([]);
        setAttachments([]);
        setSuccessMessage("SecureMail.MessageSuccessfullySent");
      })
      .catch(() => {
          setErrorMessage("SecureMail.MessageError");
      })
      .finally(() => {
        setSendingMessage(false);
      });
    }
  }, [subject, body, recipientsTo, recipientsCc, recipientsBcc, attachments, encryptAttachments, encryptBody, teamsContext]);

  const handleRecipientToDelete = (i: number) => {
    setRecipientsTo(recipientsTo.filter((r, idx) => idx !== i));
  }

  const handleRecipientToAddition = (recipient: Tag) => {
    // Change it to keep only email
    setRecipientsTo([...recipientsTo, { id: recipient.id, name: recipient.id.toString() }]);
  }

  const handleRecipientCcDelete = (i: number) => {
    setRecipientsCc(recipientsCc.filter((r, idx) => idx !== i));
  }

  const handleRecipientCcAddition = (recipient: Tag) => {
    // Change it to keep only email
    setRecipientsCc([...recipientsCc, { id: recipient.id, name: recipient.id.toString() }]);
  }

  const handleRecipientBccDelete = (i: number) => {
    setRecipientsBcc(recipientsBcc.filter((r, idx) => idx !== i));
  }

  const handleRecipientBccAddition = (recipient: Tag) => {
    // Change it to keep only email
    setRecipientsBcc([...recipientsBcc, { id: recipient.id, name: recipient.id.toString() }]);
  }

  const handleFilterSuggestions = (textInputValue: string, possibleSuggestions: Tag[]): Tag[] => {
    if (textInputValue && (!possibleSuggestions || possibleSuggestions.length === 0 || (possibleSuggestions.length === 1 && possibleSuggestions[0].id === 0))) {
      const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(textInputValue.trim());
      if (isValidEmail) {
        return [{ id: textInputValue, name: textInputValue.trim() }];
      }
      else {
        return [];
      }
    }
    return possibleSuggestions;
  }

  const generateSuggestions = (text: string) => {
    if (text && text.length >= 2) {
      const tApi = new DifensoTeamsApi('api');
      tApi.getUsers(text.trim()).then((users) => {
        const sugg = users.data.map((u) => { return { id: u.mail, name: `${u.displayName} (${u.mail})` } });
        setSuggestions(sugg);
      });
    }
  }

  const handleValidation = (tag: Tag) => {
    setSuggestions([]);
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_${{|}~-]+@[a-} (${u.mail})`zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(tag.id.toString());
  }

  useEffect(() => {
    if (!userIP) {
      const tApi = new DifensoTeamsApi('api');
      tApi.getUserIP().then((uIP) => {
        setUserIP(uIP.data);
      });
    }
  }, [userIP]);

  if (isMobile)
    return <SecureMailMobile
      sendMail={sendMail}
      subject={subject}
      setSubject={setSubject}
      body={body}
      setBody={setBody}
      generateSuggestions={generateSuggestions}
      handleFilterSuggestions={handleFilterSuggestions}
      handleValidation={handleValidation}
      handleRecipientToAddition={handleRecipientToAddition}
      handleRecipientToDelete={handleRecipientToDelete}
      handleRecipientCcAddition={handleRecipientCcAddition}
      handleRecipientCcDelete={handleRecipientCcDelete}
      handleRecipientBccAddition={handleRecipientBccAddition}
      handleRecipientBccDelete={handleRecipientBccDelete}
      suggestions={suggestions}
      recipientsTo={recipientsTo}
      recipientsCc={recipientsCc}
      recipientsBcc={recipientsBcc}
      encryptBody={encryptBody}
      setEncryptBody={setEncryptBody}
      attachments={attachments}
      setAttachments={setAttachments}
      encryptAttachments={encryptAttachments}
      setEncryptAttachments={setEncryptAttachments}
      sendingMessage={sendingMessage}
      successMessage={successMessage}
      errorMessage={errorMessage}
    />;
  else
    return <SecureMailDesktopWeb
      sendMail={sendMail}
      subject={subject}
      setSubject={setSubject}
      body={body}
      setBody={setBody}
      generateSuggestions={generateSuggestions}
      handleFilterSuggestions={handleFilterSuggestions}
      handleValidation={handleValidation}
      handleRecipientToAddition={handleRecipientToAddition}
      handleRecipientToDelete={handleRecipientToDelete}
      handleRecipientCcAddition={handleRecipientCcAddition}
      handleRecipientCcDelete={handleRecipientCcDelete}
      handleRecipientBccAddition={handleRecipientBccAddition}
      handleRecipientBccDelete={handleRecipientBccDelete}
      suggestions={suggestions}
      recipientsTo={recipientsTo}
      recipientsCc={recipientsCc}
      recipientsBcc={recipientsBcc}
      encryptBody={encryptBody}
      setEncryptBody={setEncryptBody}
      attachments={attachments}
      setAttachments={setAttachments}
      encryptAttachments={encryptAttachments}
      setEncryptAttachments={setEncryptAttachments}
      sendingMessage={sendingMessage}
      successMessage={successMessage}
      errorMessage={errorMessage}

    />;
};

export default SecureMail;
