import React, { useEffect, useState } from "react";
import { Stack } from "office-ui-fabric-react";
import { initializeIcons } from "@uifabric/icons";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";
import IUser from "./models/IUser";
import Config from "./routes/config/Config";
import Drive from "./routes/drive/Drive";
import Consent from "./routes/consent/Consent";
import SecureMail from "./routes/secureMail/SecureMail";
import AuthenticatedRoute from "./routes/authenticatedRoute/AuthenticatedRoute";
import SecureChatMessage from "./routes/chatMessage/SecureChatMessage";
import ConsentRequired from "./components/ConsentRequired/ConsentRequired";
import DifensoPersonalApi from "./api/DifensoPersonalApi";
import { AppContextProvider } from "./contexts/AppContext";
import ScrollToTop from "./common/ScrollToTop";
import { useTranslation } from "react-i18next";
import DifensoTeamsApi from "./api/DifensoTeamsApi";
import "./App.scss";

const App: React.FC = () => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [currentUserToken, setCurrentUserToken] = useState<string>();
  const [currentUserDwsPassword, setCurrentUserDwsPassword] = useState<string | undefined>(localStorage.getItem('dwspwd') ?? undefined);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [needConsent, setNeedConsent] = useState<string | undefined>();
  const [currentTeamsContextInitialized, setCurrentTeamsContextInitialized] = useState<microsoftTeams.Context>();
  const [drive, setDrive] = useState<microsoftGraph.Drive | undefined>(undefined);
  const { i18n } = useTranslation();
  const refreshInterval = 60 * 1000;
  const [currentIntervalHandle, setCurrentIntervalHandle] = useState<number>();

  useEffect(() => {
    if (!currentIntervalHandle && currentUserToken) {
      const handle = window.setInterval(() => {

        const jwt = JSON.parse(atob(currentUserToken.split('.')[1]))
        const secs = Math.floor(jwt.exp - (Date.now() / 1000));
        //console.log('time before refresh : ' + secs);

        if (secs < 200) {
          microsoftTeams.authentication.getAuthToken({
            silent: true,
            successCallback: (token) => {
              // Change only if different
              if (token !== currentUserToken) {
                console.log(`[${new Date().toISOString()} - token has changed, update it`);
                setCurrentUserToken(token);
              }
              else {
                console.log(`[${new Date().toISOString()} - token has not changed`);
              }
            },
            failureCallback: (reason) => {
            },
          });
        }
      }, refreshInterval);

      setCurrentIntervalHandle(handle);
    }
  }, [currentIntervalHandle, currentUserToken, refreshInterval]);

  useEffect(() => {
    if (firstLoad) {
      initializeIcons();
      initializeFileTypeIcons();
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    if (currentTeamsContextInitialized && currentUserToken && currentUserDwsPassword) {
      const tApi = new DifensoTeamsApi('api');
      tApi.validateDwsPassword(currentTeamsContextInitialized.userPrincipalName || '', currentUserDwsPassword)
        .then((result) => {
          if (result.data) {
            const personalApi = new DifensoPersonalApi("api", currentUserToken, window.location.origin);
            personalApi.getMe(currentUserDwsPassword).then((usr) => {
              if (
                usr &&
                currentTeamsContextInitialized.userPrincipalName === usr.username &&
                currentTeamsContextInitialized.userObjectId === usr.id &&
                currentTeamsContextInitialized.tid === usr.tenantId
              ) {
                usr.dwsPassword = currentUserDwsPassword;
                setCurrentUser(usr);
              } else {
                setNeedConsent("UserNull");
              }
            });
          } else {
            localStorage.removeItem('dwspwd');
            setCurrentUserDwsPassword(undefined);
          }
        }).catch((error) => {
          console.log(error);
          localStorage.removeItem('dwspwd');
          setCurrentUserDwsPassword(undefined);
        });
    }
  }, [currentUserToken, currentTeamsContextInitialized, currentUserDwsPassword]);

  useEffect(() => {
    if (!currentTeamsContextInitialized) {
      microsoftTeams.initialize();
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.appInitialization.notifySuccess();

      microsoftTeams.authentication.getAuthToken({
        silent: true,
        successCallback: (token) => setCurrentUserToken(token),
        failureCallback: (reason) => {
          setNeedConsent(reason);
          console.log(reason);
        },
      });

      microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
        if (!theme || theme === "default") theme = "light";
        const htmlBody = document.getElementsByTagName("body")[0];
        if (htmlBody !== null) {
          htmlBody.className = "theme-" + theme;
        }
      });
      microsoftTeams.getContext((msTeamsContext) => {
        setCurrentTeamsContextInitialized(msTeamsContext);
        i18n.changeLanguage(msTeamsContext.locale);
      });
    }
  }, [currentTeamsContextInitialized, i18n]);

  const launchConsent = () => {
    microsoftTeams.authentication.authenticate({
      url: window.location.origin + "/consent",
      width: 600,
      height: 570,
      successCallback: (result) => document.location.reload(),
      failureCallback: (reason) => setNeedConsent("ConsentFailure"),
    });
  };

  return (
    <AppContextProvider
      value={{
        currentUser,
        setCurrentUser,
        currentUserDwsPassword,
        setCurrentUserDwsPassword,
        teamsContext: currentTeamsContextInitialized,
        setTeamsContext: setCurrentTeamsContextInitialized,
        drive,
        setDrive,
      }}
    >
      {needConsent && <ConsentRequired launchConsent={launchConsent} />}
      {!needConsent && (
        <Router>
          <Stack tokens={{ childrenGap: 10 }}>            
            <ScrollToTop />
            <Switch>
              <AuthenticatedRoute path="/tabs/config">
                <Config CurrentUser={currentUser} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/tabs/teams">
                <Drive DriveType="teams" />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/tabs/sharepoint">
                <Drive DriveType="sharepoint" />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/tabs/onedrive">
                <Drive DriveType="onedrive" />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/tabs/securemail">
                <SecureMail />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/extensions/chat-encrypt">
                <SecureChatMessage action="encrypt" />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/extensions/chat-decrypt">
                <SecureChatMessage action="decrypt" />
              </AuthenticatedRoute>
              <Route path="/consent">
                <Consent action="start" />
              </Route>
              <Route path="/consented">
                <Consent action="finish" />
              </Route>
            </Switch>
          </Stack>
        </Router>
      )}
    </AppContextProvider>
  );
};

export default App;
