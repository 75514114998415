import React from "react";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";
import IUser from "../models/IUser";

export interface IAppContext {
  currentUser: IUser | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  currentUserDwsPassword: string | undefined;
  setCurrentUserDwsPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
  teamsContext: microsoftTeams.Context | undefined;
  setTeamsContext: React.Dispatch<React.SetStateAction<microsoftTeams.Context | undefined>>;
  drive: microsoftGraph.Drive | undefined;
  setDrive: React.Dispatch<React.SetStateAction<microsoftGraph.Drive | undefined>>;
}

const AppContext: React.Context<IAppContext> = React.createContext({
  currentUser: undefined,
  setCurrentUser: () => {},
  currentUserDwsPassword: undefined,
  setCurrentUserDwsPassword: () => {},
  teamsContext: undefined,
  setTeamsContext: () => {},
  drive: undefined,
  setDrive: () => {},
} as IAppContext);

export const AppContextProvider = AppContext.Provider;

export default AppContext;
