import React, { useContext, useEffect, useState } from "react";
import {PrimaryButton, DialogFooter, DefaultButton, Dialog, SpinnerSize, Spinner, DialogType, Icon} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import AppContext from "../../contexts/AppContext";

export interface ApprovalRequestDialogProps {
  fileId: string | undefined;
  onDialogClosed: () => void;
}

const ApprovalRequestDialog: React.FC<ApprovalRequestDialogProps> = (props: ApprovalRequestDialogProps) => { 
  const [hideApprovalDialog, setHideApprovalDialog] = useState<boolean>(true);
  const [sendingApproval, setSendingApproval] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const {t} = useTranslation();

  const appContext = useContext(AppContext);
  
  useEffect(() => {
    if (props.fileId) {
        setHideApprovalDialog(false);
    }
  }, [props.fileId]);

  function closeApprovalDialog() {
    setHideApprovalDialog(true);
    setSendingApproval(false);
    props.onDialogClosed();
  }
  
  function sendApprovalRequest() {
    if (appContext.currentUser && appContext.drive?.id && props.fileId && appContext.teamsContext)
    {
      setSendingApproval(true);
      const fileApi = new DifensoTeamsApi("api");
      const source = document.location.href.indexOf('/tabs/teams?') > 0 ? "Teams" : "SharePoint";

      fileApi.requestAccess(appContext.drive.id, props.fileId, source, appContext.teamsContext).then(() => {
        closeApprovalDialog();
      }).catch((reason) => {
        console.info('not sent', reason);
        setErrorMessage(t("Error.ApprovalRequest"));
        //closeApprovalDialog();
      });
    }
    else {
      // should not happen
      setErrorMessage(t("Error.Generic"));
      //closeApprovalDialog();
    }
  }

  return (
    <Dialog hidden={hideApprovalDialog} onDismiss={closeApprovalDialog} dialogContentProps={{ title: t('ApprovalRequestDialog.Title'), type: DialogType.normal }}>
        <div className="approvalRequestDialogText">{t("ApprovalRequestDialog.Text")}</div>
        <div className="approvalRequestDialogSubText">{t("ApprovalRequestDialog.SubText")}</div>
        { errorMessage && (
            <span className="error"><Icon iconName="error"></Icon> {errorMessage}</span>
        )}
        <DialogFooter>
          <DefaultButton text={t("ApprovalRequestDialog.Cancel")} onClick={closeApprovalDialog} />
          {sendingApproval && (
          <Spinner size={SpinnerSize.small} styles={{root:[{display: 'inline-flex' }]}} />
          )}
          {!sendingApproval && (
          <PrimaryButton text={t("ApprovalRequestDialog.Send")} onClick={sendApprovalRequest} className="primaryButton" />
          )}
        </DialogFooter>
    </Dialog>
  );
};

export default ApprovalRequestDialog;
