import React, { useContext, useEffect, useState } from "react";
import { PrimaryButton, DialogFooter, DefaultButton, Dialog, SpinnerSize, Spinner, DialogType, Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import AppContext from "../../contexts/AppContext";

export interface ConfirmDeleteDialogProps {
  fileId: string | undefined;
  fileName: string | undefined;
  onDialogClosed: (deleted: boolean) => void;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = (props: ConfirmDeleteDialogProps) => {
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [deletingFile, setDeletingFile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const { fileId, fileName, onDialogClosed } = props;

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (fileId && fileName) {
      setHideDeleteDialog(false);
    }
  }, [fileId, fileName]);


  function closeDialog(deleted: boolean) {
    setHideDeleteDialog(true);
    setDeletingFile(false);
    onDialogClosed(deleted);
  }

  function confirmAction() {
    if (appContext.currentUser && appContext.drive?.id && fileId && appContext.teamsContext) {
      setDeletingFile(true);
      const fileApi = new DifensoTeamsApi("api");
      fileApi.deleteFile(appContext.drive.id, fileId).then(() => {
        closeDialog(true);
      }).catch(() => {
        setErrorMessage(t("Error.FileDelete"));
      });
    }
    else {
      // should not happen
      setErrorMessage(t("Error.Generic"));
    }
  }

  return (
    <Dialog hidden={hideDeleteDialog} onDismiss={() => closeDialog(false)} dialogContentProps={{ title: t('ConfirmDeleteDialog.Title'), type: DialogType.normal }}>
      <div className="ConfirmDeleteDialogText">{t("ConfirmDeleteDialog.Text")}</div>
      <div className="ConfirmDeleteDialogSubText">{t("ConfirmDeleteDialog.Filename")}: <b>{fileName}</b></div>
      {errorMessage && (
        <span className="error"><Icon iconName="error"></Icon> {errorMessage}</span>
      )}
      <DialogFooter>
        <DefaultButton text={t("ConfirmDeleteDialog.Cancel")} onClick={() => closeDialog(false)} />
        {deletingFile && (
          <Spinner size={SpinnerSize.small} styles={{ root: [{ display: 'inline-flex' }] }} />
        )}
        {!deletingFile && (
          <PrimaryButton text={t("ConfirmDeleteDialog.Delete")} onClick={confirmAction} className="primaryButton" />
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
