import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Breadcrumb,
  IBreadcrumbItem,
  TooltipHost,
  TooltipOverflowMode,
  Stack,
  Image,
  Link,
  Icon,
} from "office-ui-fabric-react";
import { DriveType } from "../../common/DriveType";
import logoVisa from "../../images/logo-visa.png";
import logoDifenso from "../../images/logo_difenso.png";
import "./styles.scss";
import AppContext from "../../contexts/AppContext";

export interface DriveBreadcrumbProps {
  driveType: DriveType;
  folderPath: string;
}

const DriveBreadcrumb: React.FC<DriveBreadcrumbProps> = (props: DriveBreadcrumbProps) => {
  const [navItems, setNavItems] = useState<IBreadcrumbItem[]>([]);
  const appContext = useContext(AppContext);
  const isMobile = appContext.teamsContext?.hostClientType === "ios" || appContext.teamsContext?.hostClientType === "android";

  useEffect(() => {
    // get all parts of the current folder path
    const items = props.folderPath.split("/").filter((i) => i !== "");
    const rootUrl = `/tabs/${props.driveType}?driveid=${appContext.drive?.id}&folderpath=`;

    // init the breadcrumb with the drive info
    let navItemsTmp: IBreadcrumbItem[] = [
      {
        key: "root",
        text: appContext.drive?.name || "",
        href: rootUrl,
      },
    ];

    // add each path part to breadcrumb
    for (let i = 0; i < items.length; i++) {
      navItemsTmp.push({
        key: `navItem${i}`,
        text: decodeURI(items[i]),
        href: `${navItemsTmp[i]?.href}/${items[i]}`,
      });
    }

    // specific info for last item
    navItemsTmp[navItemsTmp.length - 1].href = undefined;
    navItemsTmp[navItemsTmp.length - 1].isCurrentItem = true;
    navItemsTmp[navItemsTmp.length - 1].as = "h2";

    // remove first item if Teams Channel
    if (props.driveType === "teams") {
      navItemsTmp.splice(0, 1);
    }

    // save the breadcrumb
    setNavItems(navItemsTmp);
  }, [props.folderPath, props.driveType, appContext.drive]);

  function onRenderRouterLink(item: IBreadcrumbItem | undefined) {
    if (item?.href) {
      return (
        <RouterLink to={item?.href} className="ms-Link ms-Breadcrumb-itemLink itemLink">
          <TooltipHost content={item.text} overflowMode={TooltipOverflowMode.Parent}>
            {item.text}
          </TooltipHost>
        </RouterLink>
      );
    } else {
      const Tag = item?.as || "span";
      return (
        <Tag className="ms-Breadcrumb-item item">
          <TooltipHost content={item?.text} overflowMode={TooltipOverflowMode.Parent}>
            {item?.text}
          </TooltipHost>
        </Tag>
      );
    }
  }

  return (
    <Stack horizontal horizontalAlign="space-between" styles={{ root: { background: "white" } }}>
      <Stack.Item grow>
        <Breadcrumb
          items={navItems}
          onRenderItem={onRenderRouterLink}
          maxDisplayedItems={isMobile ? 0 : (navItems.length > 3 ? 3 : undefined)}
          overflowIndex={(isMobile || navItems.length < 3) ? 0 : 1 }
          onRenderOverflowIcon={() => {
            return <Icon iconName={ isMobile ? 'Breadcrumb' : 'More' } styles={{ root: { marginTop: 5 } }} />;
          }} />
      </Stack.Item>
      <Stack horizontal verticalAlign="center">
        <Link href="https://www.difenso.com/" target="_blank">
          <Image src={logoDifenso} alt="Difenso" styles={{ root: { marginRight: 24 } }} />
        </Link>
        <Link href="https://www.ssi.gouv.fr/entreprise/certification_cspn/difenso-core-system/" target="_blank">
          <Image src={logoVisa} alt="ANSSI" styles={{ root: { marginRight: 24 }, image: { width: isMobile ? '50px' : 'auto' } }} />
        </Link>
      </Stack>
    </Stack>
  );
};

export default DriveBreadcrumb;
