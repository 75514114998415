import BaseApi from "./BaseApi";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";

/**
 * The Drive API class.
 *
 * @class
 * @param {string} _bearer The connected user bearer.
 */
class DriveApi extends BaseApi {
  /**
   * Get the drive informations.
   *
   * @return {Promise} The request promise.
   */
  public getDrive = async (): Promise<microsoftGraph.Drive> => {
    const res = await this._get("", {});
    return res.data;
  };

  /**
   * Get the drives.
   *
   * @return {Promise} The request promise.
   */
  public getDrives = async (): Promise<microsoftGraph.Drive[]> => {
    const res = await this._get("", {});
    return (res.data.value as microsoftGraph.Drive[]).filter((drive) => {
      const systemDrives = ["AppCatalog", "SearchConfig", "AppPackages", "Translation%20Packages", "ClientSideAssets"];
      let isNotSystem = true;
      systemDrives.forEach((sd) => {
        isNotSystem = isNotSystem && (drive.webUrl || "").indexOf(sd) < 0;
      });
      return isNotSystem;
    });
  };

  /**
   * Get the drive item.
   *
   * @param  {string}  itemId The Drive Item ID.
   * @return {Promise} The request promise.
   */
  public getDriveItem = async (itemId: string): Promise<microsoftGraph.DriveItem> => {
    const itemPath = !itemId ? "items/root" : `items/${itemId}`;
    const res = await this._get(itemPath, {});
    return res.data;
  };

  /**
   * Delete encrypted file.
   *
   * @return {Promise} The request promise.
   */
  public delete = async (driveId: string, fileId: string): Promise<any> => {
    const res = await this._delete(`items/${fileId}`, null);
    return res;
  };

  /**
   * Get the drive item by item path.
   *
   * @param  {string}  path The item path.
   * @return {Promise} The request promise.
   */
  public getDriveItemByPath = async (path: string): Promise<microsoftGraph.DriveItem> => {
    const itemPath = !path ? "root" : `root:${path}`;
    const res = await this._get(itemPath, {});
    return res.data;
  };

  /**
   * Get the drive items.
   *
   * @param  {string}  folderId The Folder ID.
   * @return {Promise} The request promise.
   */
  public getDriveItems = async (folderId: string, pageSize: number): Promise<microsoftGraph.DriveItem[]> => {
    const folderPath = !folderId ? "root/children" : `items/${folderId}/children`;
    const res = await this._get(`${folderPath}?$top=${pageSize}`, {});

    if (res.data["@odata.nextLink"]) {
      const nextPageItems = await this.getDriveItemsNexPage(res.data["@odata.nextLink"]);
      return res.data.value.concat(nextPageItems);
    } else {
      return res.data.value;
    }
  };

  /**
   * Get the drive items by folder path.
   *
   * @param  {string}  path The parent item path.
   * @return {Promise} The request promise.
   */
  public getDriveItemsByPath = async (path: string, pageSize: number): Promise<microsoftGraph.DriveItem[]> => {
    const folderPath = !path ? "root/children" : `root:${path}:/children`;
    const res = await this._get(`${folderPath}?$top=${pageSize}`, {});

    if (res.data["@odata.nextLink"]) {
      const nextPageItems = await this.getDriveItemsNexPage(res.data["@odata.nextLink"]);
      return res.data.value.concat(nextPageItems);
    } else {
      return res.data.value;
    }
  };

  /**
   * Get the next page of drive items.
   *
   * @param  {string}  nextUrl The next url provided by Microsoft Graph.
   * @return {Promise} The request promise.
   */
  public getDriveItemsNexPage = async (nextUrl: string): Promise<microsoftGraph.DriveItem[]> => {
    const path = nextUrl.replace(`${this.baseUrl}${this.scope}`, "");
    const res = await this._get(path, {});

    if (res.data["@odata.nextLink"]) {
      const nextPageItems = await this.getDriveItemsNexPage(res.data["@odata.nextLink"]);
      return res.data.value.concat(nextPageItems);
    } else {
      return res.data.value;
    }
  };
}

export default DriveApi;
