import axios, {AxiosResponse} from "axios";

/**
 * The baseAPI class.
 *
 * @class
 * @param {string} _scope   The targeted api scope.
 * @param {string} _bearer  The connected user bearer.
 * @param {string} _baseUrl The base url.
 */
class BaseApi {
  protected scope: string;
  protected bearer: string;
  protected baseUrl: string;

  constructor(_scope: string, _bearer: string, _baseUrl?: string) {
    this.scope = _scope === "" ? _scope : "/" + _scope;
    this.bearer = _bearer;
    this.baseUrl = _baseUrl ? _baseUrl : "https://graph.microsoft.com/v1.0";
  }

  /**
   * Encapsulation of the axios get request.
   *
   * @param   {string}  endpoint request endpoint.
   * @param   {any}     params   request params.
   * @param   {any}     headers  headers.
   * @returns {Promise} The request promise.
   */
  protected _get(endpoint: string, params: any, headers?: any): Promise<AxiosResponse> {
    let defaultHeaders: any = {};
    if (this.bearer) defaultHeaders.Authorization = `Bearer ${this.bearer}`;

    return axios.get(`${this.baseUrl}${this.scope}${endpoint === "" ? endpoint : "/" + endpoint}`, {
      ...params,
      headers: defaultHeaders,
    });
  }

  /**
   * Get endpoint URL
   *
   * @param   {string}  endpoint request endpoint.
   * @param   {any}     params   request params.
   * @returns {string}  The request URL.
   */
  protected _getUrl(endpoint: string, params?: any) {
    let queryString = "";
    if (params) {
      queryString = Object.keys(params)
        .map(function (key) {
          return key + "=" + params[key];
        })
        .join("&");
    }

    return `${this.baseUrl}${this.scope}${endpoint === "" ? endpoint : "/" + endpoint}${
      queryString ? `?${queryString}` : ""
    }`;
  }

  /**
   * Encapsulation of the axios post request.
   *
   * @param   {string}  endpoint request endpoint.
   * @param   {any}     params   request params.
   * @param   {any}     headers  headers.
   * @returns {Promise} The request promise.
   */
  protected _post(endpoint: string, params: any, headers?: any): Promise<AxiosResponse> {
    let defaultHeaders: any = {};
    if (this.bearer) defaultHeaders.Authorization = `Bearer ${this.bearer}`;

    return axios.post(`${this.baseUrl}${this.scope}${endpoint === "" ? endpoint : "/" + endpoint}`, params, {
      headers: {...defaultHeaders, ...headers},
    });
  }

  /**
   * Encapsulation of the axios delete request.
   *
   * @param   {string}  endpoint request endpoint.
   * @param   {any}     params   request params.
   * @param   {any}     headers  headers.
   * @returns {Promise} The request promise.
   */
  protected _delete(endpoint: string, params: any, headers?: any): Promise<AxiosResponse> {
    let defaultHeaders: any = {};
    if (this.bearer) defaultHeaders.Authorization = `Bearer ${this.bearer}`;

    return axios.delete(`${this.baseUrl}${this.scope}${endpoint === "" ? endpoint : "/" + endpoint}`, {
      headers: {...defaultHeaders, ...headers},
    });
  }

  /**
   * Encapsulation of the axios put request.
   *
   * @param   {string}  endpoint request endpoint.
   * @param   {any}     params   request params.
   * @param   {any}     headers  request headers.
   * @returns {Promise} The request promise.
   */
  protected _put(endpoint: string, params: any, headers?: any): Promise<AxiosResponse> {
    let defaultHeaders: any = {};
    if (this.bearer) defaultHeaders.Authorization = `Bearer ${this.bearer}`;

    return axios.put(`${this.baseUrl}${this.scope}${endpoint === "" ? endpoint : "/" + endpoint}`, params, {
      headers: {...defaultHeaders, ...headers},
    });
  }
}

export default BaseApi;
