import React, {useContext, useState} from "react";
import {
  CommandBar,
  ICommandBarItemProps,
  SearchBox,
  Spinner,
  Stack,
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
} from "office-ui-fabric-react";
import * as microsoftGraph from "@microsoft/microsoft-graph-types";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import AppContext from "../../contexts/AppContext";
import ApprovalRequestDialog from "../ApprovalRequestDialog/ApprovalRequestDialog";
import {useTranslation} from "react-i18next";
import "./styles.scss";
import { Icon } from "@fluentui/react/lib/Icon";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog/ConfirmDeleteDialog";
import ViewPermissionsDialog from "../ViewPermissionsDialog/ViewPermissionsDialog";

export interface DriveMenuProps {
  updateFilter: (filterText: string) => void;
  clickUploadButton: () => void;
  clickRefreshButton: () => void;
  clickVisualizeButton: () => void;
  clickDeleteButton: () => void;
  selectedItem: microsoftGraph.DriveItem | undefined;
  uploadingFilenames: string[];
  errorMessage: string | undefined;
  informationMessage: string | undefined;
}

const DriveMenu: React.FC<DriveMenuProps> = (props: DriveMenuProps) => {
  const [downloadingFilename, setDownloadingFilename] = useState<string | undefined>(undefined);
  const appContext = useContext(AppContext);
  const [fileIdToApprove, setFileIdToApprove] = useState<string | undefined>(undefined);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | undefined>(undefined);
  const [fileIdToVisualize, setFileIdToVisualize] = useState<string | undefined>(undefined);
  const [fileNameToDelete, setFileNameToDelete] = useState<string | undefined>(undefined);
  const {t} = useTranslation();
  const isMobile =
    appContext.teamsContext?.hostClientType === "ios" || appContext.teamsContext?.hostClientType === "android";

  const items: ICommandBarItemProps[] = [
    {
      key: "upload",
      text: t("Menu.Upload"),
      iconProps: {iconName: "Upload"},
      onClick: props.clickUploadButton,
    },
    {
      key: "download",
      text: t("Menu.Download"),
      iconProps: {iconName: "Download"},
      disabled: !props.selectedItem?.file,
      onClick: isMobile ? handleDownloadMobileClick : handleDownloadClick,
    },
    {
      key: "refresh",
      text: t("Menu.Refresh"),
      iconProps: {iconName: "Refresh"},
      onClick: () => props.clickRefreshButton(),
    },
    {
      key: "visualize",
      text: t("Menu.ViewPermissions"),
      iconProps: {iconName: "View"},
      disabled: (!props.selectedItem?.file || !props.selectedItem.name || props.selectedItem.name?.indexOf('.encrypt') < 0),
      onClick: () => handleVisualizeClick(),
    },
    {
      key: "delete",
      text: t("Menu.Delete"),
      iconProps: {iconName: "Delete"},
      disabled: (!props.selectedItem?.file || !props.selectedItem.name || props.selectedItem.name?.indexOf('.encrypt') < 0),
      onClick: () => handleDeleteClick(),
    },
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: "status",
      onRender: () => {
        return (
          <Stack verticalAlign="center">
            {(props.uploadingFilenames.length > 0 || downloadingFilename) && (
              <TooltipHost
                delay={TooltipDelay.zero}
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => (
                    <div>
                      {props.uploadingFilenames.map((filename) => (
                        <div key={filename}>{filename}</div>
                      ))}
                      {downloadingFilename}
                    </div>
                  ),
                }}
              >
                <Spinner
                  label={downloadingFilename ? t("Menu.DownloadProgress") : t("Menu.UploadProgress")}
                  labelPosition="left"
                  styles={{
                    root: [{marginRight: 24}],
                    label: [{color: "#6264a7"}],
                    circle: [{borderColor: "#6264a7 #e0e0ed #e0e0ed"}],
                  }}
                />
              </TooltipHost>
            )}
            {props.errorMessage && (
              <TooltipHost
                delay={TooltipDelay.medium}
                className="error"
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => <div className="error">{props.errorMessage}</div>,
                }}
              >
                <span className="error">
                  <Icon iconName="error"></Icon> {t("Error.Generic")}
                </span>
              </TooltipHost>
            )}
            {props.informationMessage && (
              <TooltipHost
                delay={TooltipDelay.medium}
                className="information"
                directionalHint={DirectionalHint.bottomCenter}
                tooltipProps={{
                  onRenderContent: () => <div className="information">{props.informationMessage}</div>,
                }}
              >
                <span className="information">
                  <Icon iconName="info"></Icon> {t("Information.UploadSuccess")}
                </span>
              </TooltipHost>
            )}
          </Stack>
        );
      },
    },
    {
      key: "search",
      onRender: () => <SearchBox placeholder={t("Menu.Filter")} className="searchbox" onChange={handleFilterChange} />,
    },
  ];

  function handleFilterChange(event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) {
    props.updateFilter(newValue || "");
  }

  function onDialogClosed() {
    setFileIdToApprove(undefined);
  }

  function onConfirmDeleteDialogClosed(deleted: boolean) {
    setFileIdToDelete(undefined);
    setFileNameToDelete(undefined);
    if (deleted) {
      props.clickRefreshButton();
    };
  }

  function handleDeleteClick() {
    setFileIdToDelete(undefined);
    setFileNameToDelete(undefined);

    if (appContext.currentUser && props.selectedItem && props.selectedItem.id && appContext.drive?.id) {
      setFileIdToDelete(props.selectedItem.id);
      setFileNameToDelete(props.selectedItem.name || '');
    }
  }

  function handleVisualizeClick () {
    setFileIdToVisualize(undefined);

    if (appContext.currentUser && props.selectedItem && props.selectedItem.id) {
      setFileIdToVisualize(props.selectedItem.id);
    }
  }

  function onConfirmVisualizeDialogClosed() {
    setFileIdToVisualize(undefined);
  }

  function handleDownloadClick() {
    setFileIdToApprove(undefined);

    if (appContext.currentUser && props.selectedItem && props.selectedItem.id && appContext.drive?.id) {
      if (props.selectedItem.name?.endsWith(".encrypt")) {
        setDownloadingFilename(props.selectedItem.name);

        const fileApi = new DifensoTeamsApi("api");
        fileApi
          .getFile(appContext.drive?.id, props.selectedItem?.id)
          .then((response) => {
            const contentType = response.headers["content-type"];
            const contentDisposition: string = response.headers["content-disposition"];

            const url = window.URL.createObjectURL(new Blob([response.data], {type: contentType}));
            const link = document.createElement("a");
            link.href = url;

            const filenameInfo = contentDisposition.split(";").find((i) => i.indexOf("filename=") > -1);
            if (filenameInfo) {
              const filename = filenameInfo.trim().replace("filename=", "").replace(/"/g, "");
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            }

            setDownloadingFilename(undefined);
          })
          .catch((reason) => {
            //
            console.log(reason);
            setDownloadingFilename(undefined);

            if (reason.response.status === 401) {
              // User has no permission to decrypt the file, ask for permissions to the owner
              setFileIdToApprove(props.selectedItem?.id);
            }
          });
      } else {
        window.location.href = (props.selectedItem as any)["@microsoft.graph.downloadUrl"];
      }
    }
  }

  function handleDownloadMobileClick() {
    setFileIdToApprove(undefined);

    if (appContext.currentUser && props.selectedItem && props.selectedItem.id && appContext.drive?.id) {
      if (props.selectedItem.name?.endsWith(".encrypt")) {
        console.log("handleDownloadMobileClick");

        setDownloadingFilename(props.selectedItem.name);

        const fileApi = new DifensoTeamsApi("api");
        fileApi
          .getFileOneTimeToken(appContext.drive?.id || "", props.selectedItem?.id || "")
          .then((ottk) => {
            const fileUrl =
              fileApi.getFileUrl(appContext.drive?.id || "", props.selectedItem?.id || "") + "/" + ottk.data.token;
            console.log(fileUrl);

            const link = document.createElement("a");
            link.href = fileUrl;
            link.target = "_blank";

            document.body.appendChild(link);
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link);
            }, 500);
          })
          .catch((reason) => {
            console.log(reason);
            // User has no permission to decrypt the file, ask for permissions to the owner
            // It will open the approval dialog
            setFileIdToApprove(props.selectedItem?.id);
          })
          .finally(() => {
            setDownloadingFilename(undefined);
          });
      } else {
        window.location.href = (props.selectedItem as any)["@microsoft.graph.downloadUrl"];
      }
    }
  }

  return (
    <div>
      <CommandBar
        items={items}
        farItems={farItems}
        overflowButtonProps={{
          menuIconProps: {
            iconName: "GlobalNavButton",
          },
        }}
      />
      <ApprovalRequestDialog fileId={fileIdToApprove} onDialogClosed={onDialogClosed}></ApprovalRequestDialog>
      <ConfirmDeleteDialog fileId={fileIdToDelete} fileName={fileNameToDelete} onDialogClosed={onConfirmDeleteDialogClosed} />
      <ViewPermissionsDialog fileId={fileIdToVisualize} onDialogClosed={onConfirmVisualizeDialogClosed} />
    </div>
  );
};

export default DriveMenu;
