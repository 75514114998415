import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { useTranslation } from "react-i18next";
import DifensoTeamsApi from "../../api/DifensoTeamsApi";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";

const delimiters = ["Comma", "Enter", "Semicolon", "Tab"];

export interface ViewPermissionsDialog {
  fileId: string | undefined;
  onDialogClosed: () => void;
}

const ViewPermissionsDialog: React.FC<ViewPermissionsDialog> = (props: ViewPermissionsDialog) => {
  const { currentUser, drive } = useContext(AppContext);
  const { fileId, onDialogClosed } = props;

  const [hideDialog, setHideDialog] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [recipients, setRecipients] = useState<Tag[]>();
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Tag[]>();
  const [updating, setUpdating] = useState<boolean>(false);

  const uParams = new URLSearchParams(document.location.search);
  const chatMessageId = uParams.get("msgId");
  //const { action } = props;
  const action = "decrypt";

  const { t } = useTranslation();

  useEffect(() => {
    if (fileId) {
      setErrorMessage(undefined);
      setHideDialog(false);
    }
  }, [fileId]);

  const updateChatMessageAuthorizations = useCallback(() => {
    if (!updating && recipients && drive?.id && fileId) {
      setUpdating(true);
      // try to update authorizations
      const tApi = new DifensoTeamsApi("api");
      tApi
        .updateAuthorizations(drive.id, fileId, recipients.map(r => r.name))
        .then((): void => {
          setHideDialog(true);
          setRecipients(undefined);
          setCanUpdate(false);
          setUpdating(false);
          onDialogClosed();
        })
        .catch((reason) => {
          if (reason.response && (reason.response.status === 403 || reason.response.status === 401)) {
            setErrorMessage("Error.ViewPermissionsNotOwner");
          } else {
            setErrorMessage("Error.Generic");
          }
        })
        .finally(() => {
          setUpdating(false);
        });
    }
  }, [updating, recipients, drive, fileId, onDialogClosed]);

  const handleRecipientDelete = (i: number) => {
    setRecipients(recipients?.filter((r, idx) => idx !== i));
  };

  const handleRecipientAddition = (recipient: Tag) => {
    // Change it to keep only email
    if (recipients)
      setRecipients([...recipients, { id: recipient.id, name: recipient.id.toString() }]);
    else
      setRecipients([{ id: recipient.id, name: recipient.id.toString() }]);
  };

  const handleFilterSuggestions = (textInputValue: string, possibleSuggestions: Tag[]): Tag[] => {
    if (textInputValue && (!possibleSuggestions || possibleSuggestions.length === 0)) {
      const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(textInputValue);
      if (isValidEmail) {
        return [{ id: textInputValue, name: textInputValue }];
      } else {
        return [];
      }
    }
    return possibleSuggestions;
  };

  const generateSuggestions = (text: string) => {
    if (text && text.length >= 2) {
      const tApi = new DifensoTeamsApi("api");
      tApi.getUsers(text).then((users) => {
        const sugg = users.data.map((u) => {
          return { id: u.mail, name: `${u.displayName} (${u.mail})` };
        });
        setSuggestions(sugg);
      });
    }
  };

  const handleValidation = (tag: Tag) => {
    setSuggestions([]);
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_${{|}~-]+@[a-} (${u.mail})`zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      tag.id.toString()
    );
  };

  useEffect(() => {
    if (currentUser && !canUpdate && drive && drive.id && fileId) {
      // try to get authorizations
      const tApi = new DifensoTeamsApi("api");
      tApi
        .listAuthorizationsAsync(drive.id, fileId)
        .then((result): void => {
          if (result.data?.authorized) {
            const rec = result.data.authorized.map((r: any) => {
              return { id: r, name: r };
            });
            setCanUpdate(rec.length > 0);
            setRecipients(rec);
          }
        })
        .catch((reason) => {
          if (reason.response && (reason.response.status === 403 || reason.response.status === 401)) {
            setErrorMessage("Error.ViewPermissionsNotOwner");
          } else {
            setErrorMessage("Error.Generic");
          }
        })
        .finally(() => {
          //setUpdating(false);
        });
    }
  }, [canUpdate, drive, fileId, action, currentUser, updating, chatMessageId]);

  function closeDialog() {
    setHideDialog(true);
    setRecipients(undefined);
    setCanUpdate(false);
    onDialogClosed();
  }

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={closeDialog}
      minWidth={400}
      dialogContentProps={{
        title: t('ViewPermissions.Title'),
        type: DialogType.normal,
        className: "ViewPermissionsDialog"
      }}>
      <div>{t("ViewPermissions.Text")}</div>
      <div className="ViewPermissionsTags">
        {recipients && (
          <ReactTags
            key="updateRecipients"
            tags={recipients.filter(r => r.id !== currentUser?.username)}
            minQueryLength={2}
            suggestions={suggestions}
            suggestionsTransform={handleFilterSuggestions}
            onDelete={handleRecipientDelete}
            onAddition={handleRecipientAddition}
            onValidate={handleValidation}
            onInput={generateSuggestions}
            delimiters={delimiters}
            placeholderText={t("ViewPermissions.AddRecipient")}
            noSuggestionsText={t("ViewPermissions.NoRecipient")}
            removeButtonText={t("ViewPermissions.RemoveRecipient")}
          />
        )}
        { !recipients && !errorMessage && (
          <Spinner size={SpinnerSize.medium} styles={{ root: [{ display: 'inline-flex' }] }} />
        )}
        { errorMessage && (
          <span className="error"><Icon iconName="error"></Icon> {t(errorMessage)}</span>
        )}
      </div>
      <DialogFooter>
        <DefaultButton text={t("ViewPermissions.Close")} onClick={closeDialog} />
        {updating && (
          <Spinner size={SpinnerSize.small} styles={{ root: [{ display: 'inline-flex' }] }} />
        )}
        {!updating && canUpdate && (
          <PrimaryButton text={t("ViewPermissions.Update")} onClick={updateChatMessageAuthorizations} className="primaryButton" />
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default ViewPermissionsDialog;
