import React, {useContext, useState, useEffect} from "react";
import IUser from "../../models/IUser";
import AppContext from "../../contexts/AppContext";
import "./styles.scss";
import * as microsoftTeams from "@microsoft/teams-js";
import DriveApi from "../../api/DriveApi";
import SharePointApi from "../../api/SharePointApi";
import {Stack} from "office-ui-fabric-react/lib/Stack";
import {Dropdown, IDropdownOption} from "office-ui-fabric-react/lib/Dropdown";
import {Icon} from "@fluentui/react/lib/Icon";
import {TagPicker, ITag, IBasePickerSuggestionsProps} from "office-ui-fabric-react/lib/Pickers";
import {useTranslation} from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

export interface ConfigProps {
  CurrentUser: IUser | undefined;
}

const Config: React.FC<ConfigProps> = (props: ConfigProps) => {
  const [source, setSource] = useState<string>("");
  const [selectedSites, setSelectedSites] = useState<ITag[] | undefined>(undefined);
  const [selectedSitesInfo, setSelectedSitesInfo] = useState<microsoftgraph.Site[] | undefined>(undefined);
  const [selectedSiteInfo, setSelectedSiteInfo] = useState<microsoftgraph.Site | undefined>(undefined);
  const [selectedLibrary, setSelectedLibrary] = useState<IDropdownOption | undefined>(undefined);
  const [libraries, setLibraries] = useState<IDropdownOption[]>([]);
  const [formValid, setFormValid] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const {t} = useTranslation();

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("Config.SuggestionsHeaderText"),
    noResultsFoundText: t("Config.NoSiteFoundText"),
  };

  // check form validity
  useEffect(() => {
    if (appContext.teamsContext) {
      let valid = false;
      if (source === "channel") {
        valid = true;
      } else if (source === "sharepoint") {
        valid = selectedSites?.length === 1 && !!selectedLibrary;
      }

      setFormValid(valid);
      microsoftTeams.settings.setValidityState(valid);
    }
  }, [appContext, source, selectedSites, selectedLibrary]);

  // save settings if valid
  useEffect(() => {
    if (formValid && appContext.currentUser && appContext.teamsContext) {
      if (source === "channel") {
        const groupId = appContext.teamsContext.groupId;
        const driveApi = new DriveApi(`groups/${groupId}/drive`, appContext.currentUser.accessToken);
        driveApi.getDrive().then((drive) => {
          const teamSiteDomain = appContext.teamsContext?.teamSiteDomain;
          const channelRelativeUrl = appContext.teamsContext?.channelRelativeUrl;
          const channelAbsoluteUrl = encodeURI(`https://${teamSiteDomain}${channelRelativeUrl}`);
          const channelDriveRelativePath = channelAbsoluteUrl.replace(drive.webUrl || "", "");
          const contentUrl = `${window.location.origin}/tabs/teams?driveid=${encodeURI(drive.id || "")}&folderpath=${encodeURI(channelDriveRelativePath)}`;

          microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.settings.setSettings({
              contentUrl: contentUrl,
              entityId: uuidv4(),
              suggestedDisplayName: document.location.hostname.indexOf('.infinitesquare.eu') > 0 ? "Difenso DEV" : "Difenso",
            });
            saveEvent.notifySuccess();
          });
        });
      } else {
        const driveId = selectedLibrary?.key.toString() || "";
        const contentUrl = `${window.location.origin}/tabs/sharepoint?driveid=${encodeURI(driveId)}`;
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          microsoftTeams.settings.setSettings({
            contentUrl: contentUrl,
            entityId: uuidv4(),
            suggestedDisplayName: "Difenso",
          });
          saveEvent.notifySuccess();
        });
      }
    }
  }, [appContext, formValid, source, selectedLibrary]);

  function handleSourceChange(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setSource(event.currentTarget.id);
  }

  function getTextFromItem(item: ITag) {
    return item.name;
  }

  async function filterSuggestedSites(value: string) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0 || !appContext.currentUser?.accessToken) {
      return [];
    }

    // get site suggestions
    const sharepointApi = new SharePointApi("sites", appContext.currentUser.accessToken);
    const sites = await sharepointApi.searchSite(inputValue);
    setSelectedSitesInfo(sites);
    return sites.map((site) => ({key: site.id, name: site.displayName} as ITag));
  }

  async function handlerSiteChange(items?: ITag[] | undefined) {
    if (!items || items.length === 0) {
      setSelectedSites([]);
      setSelectedSitesInfo([]);
      setSelectedSiteInfo(undefined);
      setLibraries([]);
      setSelectedLibrary(undefined);
    }

    setSelectedSites(items);

    // get libraries suggestions
    if (appContext.currentUser && items?.length === 1) {
      const site = selectedSitesInfo ? selectedSitesInfo.find((site) => site.id === items[0].key) : undefined;
      setSelectedSiteInfo(site);

      const siteId = items[0].key;
      const driveApi = new DriveApi(`sites/${siteId}/drives`, appContext.currentUser.accessToken);
      const libraries = await driveApi.getDrives();
      const options = libraries.map(
        (library) =>
          ({
            key: library.id,
            text: library.name,
          } as IDropdownOption)
      );
      setLibraries(options);
    }
  }

  function handleLibraryChange(
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    setSelectedLibrary(option);
  }

  return (
    <div>
      {appContext.currentUser && (
        <Stack tokens={{childrenGap: 20}}>
          {source !== "sharepoint" && (
            <div>
              <div className="titleSection">{t("Config.Select")}</div>
              <div className="description">{t("Config.FilesSourceLocation")}</div>
              <Stack tokens={{childrenGap: 25}}>
                <Stack
                  id="channel"
                  className={`sourceItem ${source === "channel" ? "active" : ""}`}
                  horizontal
                  onClick={handleSourceChange}
                >
                  <Stack.Item className="sourceIconContainer">
                    <Icon iconName="PageList" className="sourceIcon" />
                  </Stack.Item>
                  <Stack.Item grow className="sourceTitle">
                    {t("Config.CurrentChannel")}
                  </Stack.Item>
                </Stack>
                <Stack
                  id="sharepoint"
                  className={`sourceItem ${source === "sharepoint" ? "active" : ""}`}
                  horizontal
                  onClick={handleSourceChange}
                >
                  <Stack.Item className="sourceIconContainer">
                    <Icon iconName="SharepointAppIcon16" className="sourceIcon" />
                  </Stack.Item>
                  <Stack.Item grow className="sourceTitle">
                    SharePoint
                  </Stack.Item>
                </Stack>
              </Stack>
            </div>
          )}
          {source === "sharepoint" && (
            <div>
              <div className="back-link" onClick={() => setSource("")}>
                &lt;&nbsp;{t("Config.Back")}
              </div>
              <div className="titleSection">{t("Config.SharePointLibrary")}</div>
              <div className="description">{t("Config.FilesSourceLocation")}</div>
              <Stack>
                <div>
                  <label>{t("Config.SearchYourSite")}</label>
                  <TagPicker
                    onResolveSuggestions={filterSuggestedSites}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    itemLimit={1}
                    selectedItems={selectedSites}
                    onChange={handlerSiteChange}
                    styles={(props) => ({
                      root: [
                        {
                          marginBottom: 5,
                        },
                      ],
                      input: [
                        {
                          backgroundColor: "#f4f4f4",
                        },
                      ],
                      text: [
                        {
                          backgroundColor: "#f4f4f4",
                          borderTopColor: "#f4f4f4",
                          borderLeftColor: "#f4f4f4",
                          borderRightColor: "#f4f4f4",
                          selectors: {
                            ":hover": {
                              borderTopColor: "#f4f4f4",
                              borderLeftColor: "#f4f4f4",
                              borderRightColor: "#f4f4f4",
                            },
                          },
                        },
                        props.isFocused &&
                          !props.disabled && {
                            selectors: {
                              ":after": {
                                borderTopColor: "#f4f4f4",
                                borderLeftColor: "#f4f4f4",
                                borderRightColor: "#f4f4f4",
                                borderBottomColor: "#464775",
                              },
                            },
                          },
                      ],
                    })}
                  />
                </div>
                <div className="sourceLink">
                  {selectedSiteInfo && (
                    <a href={selectedSiteInfo.webUrl || "#"} target="_blank" rel="noopener noreferrer">
                      {selectedSiteInfo.webUrl}
                    </a>
                  )}
                </div>
                <div>
                  <Dropdown
                    label={t("Config.SelectYourLibrary")}
                    selectedKey={selectedLibrary ? selectedLibrary.key : undefined}
                    onChange={handleLibraryChange}
                    placeholder={t("Config.SelectALibrary")}
                    options={libraries}
                    disabled={!selectedSites || selectedSites.length === 0}
                    styles={{
                      label: [
                        {
                          fontSize: 12,
                          color: "#333333",
                          padding: "7px 0",
                          fontWeight: "normal",
                        },
                      ],
                    }}
                  />
                </div>
              </Stack>
            </div>
          )}
        </Stack>
      )}
    </div>
  );
};

export default Config;
