import React, { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import Authenticate from '../../components/Authenticate/Authenticate';

export interface AuthenticatedRouteProps {
  path: string;
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
// eslint-disable-next-line react/prop-types
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, ...props }) => {
  const { currentUserDwsPassword } = useContext(AppContext);

  return (<>
    { (currentUserDwsPassword) ? children :  (<Authenticate />) }
  </>);  
};

export default AuthenticatedRoute;
